import React, { useState } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
  selectCallCentreNumber,
  selectDonationInfo,
  selectDonorInfo,
  selectFormErrors,
  selectFormLanguage,
  // selectGiftNotes,
  selectIsCompanyGift,
  selectIsPrintReceipt,
  selectLoyaltyCardNumber,
  selectSource,
} from "redux/form/form.selector";
import {
  selectAuthenticationToken,
  selectCurrentForm,
  selectPopup,
  selectPresValue,
} from "redux/helpers/helpers.selector";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  updateCallCentreNumber,
  updateDonationInfo,
  updateDonorInfo,
  updateFormErrors,
  updateIsCompanyGift,
  updateIsPrintReceipt,
} from "redux/form/form.reducer";
import {
  updateCurrentForm,
  updatePopup,
  updatePresValue,
} from "redux/helpers/helpers.reducer";
import { FormSourceType, ValidationErrors } from "utils/interfaces";

import {
  CompanyGiftText,
  CompanyGiftContainer,
  RadioLabel,
  RadioContainer,
  CallCenterEmailDisclaimer,
  CCEmailText,
} from "./donor-info-step.styles";

import {
  BottomButtonsContainer,
  NextStepButton,
  SectionHeading,
  RequiredText,
  InputRow,
  FormPartContainer,
  CheckboxContainer,
  // TextAreaCharLimit,
  // TextArea,
} from "global";

import BackButton from "components/back-button/back-button.component";
import {
  scrollToTheError,
  scrollToTheTop,
  validate,
} from "utils/helper-functions";

import { schemaConstructor } from "utils/validation-schemas";
import { useTranslation } from "react-i18next";
import AddressComplete from "components/address-complete/address-complete.component";
import { useSearchParams } from "react-router-dom";
import analyticsAPI from "utils/analyticsAPI";
import lookup from "country-code-lookup";
import { CountryCode } from "libphonenumber-js";
import {
  countriesCollection,
  getTitleSelectValues,
  nameRegex,
  popupMessages,
  provincesCollection,
  addressRegex,
  statesCollection,
  emailRegex,
  postalCodeRegex,
  phoneNumberRegex,
  crmIDRegex,
} from "utils/variables";
import Select from "components/select/select.component";
import { ValidationError } from "yup";
import Input from "components/input/input.component";

const DonorInfoStep: React.FC = () => {
  // URL parameters
  const [searchParams] = useSearchParams();
  const presValueURL = searchParams.get("pres") || searchParams.get("s_pres");

  // GLOBAL STATE
  const currentForm = useAppSelector(selectCurrentForm);
  const donorInfo = useAppSelector(selectDonorInfo);
  const donationInfo = useAppSelector(selectDonationInfo);
  const formErrors = useAppSelector(selectFormErrors);
  const isCompanyGift = useAppSelector(selectIsCompanyGift);
  const loyaltyCardNumber = useAppSelector(selectLoyaltyCardNumber);
  const isPrintReceipt = useAppSelector(selectIsPrintReceipt);
  const callCentreNumber = useAppSelector(selectCallCentreNumber);
  const source = useAppSelector(selectSource);
  // const giftNotes = useAppSelector(selectGiftNotes);
  const authenticationToken = useAppSelector(selectAuthenticationToken);
  const presValue = useAppSelector(selectPresValue);
  const popup = useAppSelector(selectPopup);
  const formLanguage = useAppSelector(selectFormLanguage);
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();

  // LOCAL STATE
  const { t } = useTranslation();
  const [isDonorWithoutEmail, setIsDonorWithoutEmail] = useState(false);
  const isDesktop = window.innerWidth > 768;
  const isCCForm = presValue === "CC";
  const isFDForm =
    source === FormSourceType.FUNDRAISING_DIRECT &&
    Boolean(authenticationToken);
  const isDevelopmentForm = source === FormSourceType.DEVELOPMENT_TEAM;
  const isGPForm = source === FormSourceType.GP_TEAM;
  const isCCFDForm = isFDForm || isCCForm;

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === "yes") {
      dispatch(updateIsCompanyGift(true));
    } else {
      dispatch(updateIsCompanyGift(false));
      dispatch(updateDonorInfo({ ...donorInfo, companyName: "" }));
    }
  };

  const handleInputAndSelect = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.currentTarget;

    if (["firstName", "lastName", "companyName"].includes(name)) {
      if (nameRegex.test(value))
        dispatch(updateDonorInfo({ ...donorInfo, [name]: value }));
    } else if (name === "country") {
      dispatch(
        updateDonorInfo({ ...donorInfo, provinceState: "", [name]: value }),
      );
    } else if (name === "email") {
      if (!emailRegex.test(value)) return;
      if (!presValueURL) dispatch(updatePresValue("default"));

      dispatch(updateDonorInfo({ ...donorInfo, email: value }));
    } else {
      if (name === "postal" && !postalCodeRegex.test(value)) {
        return;
      } else if (!addressRegex.test(value)) {
        return;
      }

      // Reset UTC offset if address changes
      if (["city", "country", "provinceState", "postal"].includes(name)) {
        dispatch(updateDonationInfo({ ...donationInfo, utcOffset: undefined }));
      }

      dispatch(
        updateDonorInfo({
          ...donorInfo,
          [name]: name === "addressLine2" ? value.replace(/=/g, "") : value,
        }),
      );
    }
  };

  const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.currentTarget.value;

    if (phoneNumberRegex.test(targetValue))
      dispatch(updateDonorInfo({ ...donorInfo, phoneNumber: targetValue }));
  };

  const handleCallCenterNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (isDevelopmentForm && !crmIDRegex.test(value)) return;
    dispatch(updateCallCentreNumber(value));
  };

  const handleNextForm = async (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent,
  ) => {
    e.preventDefault();
    analyticsAPI.handleNextStep(appInsights, { currentForm });
    const schema = schemaConstructor("donorInfo", {
      locale: lookup.byCountry(donorInfo.country)?.iso2 as CountryCode,
      presValue,
      isInternalForm: isCCFDForm || isDevelopmentForm,
      isGPForm,
      isDevelopmentForm,
      isAuthenticatedForm: Boolean(authenticationToken),
    });

    try {
      // Validate
      await validate(
        { ...donorInfo, loyaltyCardNumber, isCompanyGift, callCentreNumber },
        schema,
      );
      dispatch(updateCurrentForm(currentForm + 1));
      dispatch(updateFormErrors({}));

      // Scroll to the top
      scrollToTheTop();
    } catch (error: unknown) {
      const errors: ValidationErrors = {};

      (error as ValidationError).inner.forEach((err) => {
        if (err.path) {
          errors[err.path] = err.message;
        }
      });

      scrollToTheError(Object.keys(errors)[0]);

      // Display popup
      dispatch(updateFormErrors(errors));
      dispatch(
        updatePopup({
          ...popup,
          message: popupMessages.errorsFixed,
          isError: true,
          isActive: true,
          isLoading: false,
        }),
      );
    }
  };

  const handleIsPrintReceipt = () => {
    const defaultEmail =
      isDevelopmentForm || isFDForm
        ? [
            donorInfo.firstName,
            donorInfo.lastName,
            donorInfo.postal,
            "@HSFgenericemail.ca",
          ]
            .map((value) => value.trim().toLowerCase().replace(/ /g, ""))
            .join("")
        : process.env.REACT_APP_DONOR_SERVICES_EMAIL;

    dispatch(
      updateDonorInfo({
        ...donorInfo,
        email: (isCCFDForm ? !isPrintReceipt : !isDonorWithoutEmail)
          ? defaultEmail
          : "",
      }),
    );

    if (isCCFDForm) {
      dispatch(updateIsPrintReceipt(!isPrintReceipt));
    } else {
      setIsDonorWithoutEmail(!isDonorWithoutEmail);
    }
  };

  const getCCFieldLabel = () => {
    if (isDevelopmentForm) return "CRM ID";
    if (isGPForm) return "Appeal Code";
    return "Contact Centre Call Number";
  };

  const getPrintReceiptCheckboxLabel = () => {
    if (isGPForm || isDevelopmentForm) {
      return "Donor does not have an email address";
    }
    return "Print receipt requested";
  };

  // HTML ELEMENTS
  const checkboxElement = (
    <CheckboxContainer style={{ marginBottom: "25px" }}>
      <input
        type="checkbox"
        id="isPrintReceipt"
        checked={isPrintReceipt || isDonorWithoutEmail}
        onChange={handleIsPrintReceipt}
      />
      <label htmlFor="isPrintReceipt">
        {t(getPrintReceiptCheckboxLabel())}
      </label>
    </CheckboxContainer>
  );

  const emailElement = (
    <Input
      label="Email Address"
      type="email"
      name="email"
      id="email"
      onChange={handleInputAndSelect}
      value={donorInfo.email}
      isError={formErrors.email}
      maxLength={150}
      disabled={isPrintReceipt || isDonorWithoutEmail}
      belowLabelContent={
        isCCFDForm && <CCEmailText>{t("Valid email address")}</CCEmailText>
      }
      isRequired
    />
  );

  return (
    <FormPartContainer currentForm={currentForm} formID={1}>
      <SectionHeading>{t("Provide your info")}</SectionHeading>
      <RequiredText>* {t("Required info")}</RequiredText>

      {/* To wrap all inputs with one container */}
      <div
        onKeyDown={(e: React.KeyboardEvent) => {
          if (isDesktop && e.key === "Enter") handleNextForm(e);
        }}
      >
        {(isCCFDForm || Boolean(authenticationToken)) && (
          <>
            <div>
              <CompanyGiftText>{t("Company gift")}</CompanyGiftText>
              <CompanyGiftContainer onChange={handleRadioChange}>
                <RadioContainer>
                  <input
                    type="radio"
                    id="radioYes"
                    name="companyGift"
                    value="yes"
                    defaultChecked={isCompanyGift}
                  />
                  <RadioLabel htmlFor="radioYes">{t("Yes")}</RadioLabel>
                </RadioContainer>
                <RadioContainer>
                  <input
                    type="radio"
                    id="radioNo"
                    defaultChecked={!isCompanyGift}
                    name="companyGift"
                    value="no"
                  />
                  <RadioLabel htmlFor="radioNo">{t("No")}</RadioLabel>
                </RadioContainer>
              </CompanyGiftContainer>
            </div>
            {isCompanyGift && (
              <Input
                label="Company Name"
                type="text"
                name="companyName"
                id="companyName"
                onChange={handleInputAndSelect}
                value={donorInfo.companyName}
                isError={formErrors.companyName}
                maxLength={200}
                isRequired
              />
            )}
          </>
        )}
        {!isCCFDForm && !isDevelopmentForm && !authenticationToken && (
          <>
            {emailElement}

            {isGPForm && checkboxElement}
          </>
        )}

        <InputRow>
          <Select
            name="title"
            id="title"
            onChange={handleInputAndSelect}
            label="Title"
            value={donorInfo.title}
            optionsData={getTitleSelectValues(formLanguage)}
            withTranslation
            withValueTranslation
          />

          <Input
            label="First Name"
            type="text"
            name="firstName"
            id="firstName"
            onChange={handleInputAndSelect}
            value={donorInfo.firstName}
            isError={formErrors.firstName}
            maxLength={50}
            isRequired
          />
          <Input
            label="Last Name"
            type="text"
            name="lastName"
            id="lastName"
            onChange={handleInputAndSelect}
            value={donorInfo.lastName}
            isError={formErrors.lastName}
            maxLength={100}
            isRequired
          />
        </InputRow>

        {!isCCFDForm && !authenticationToken && (
          <>
            <div>
              <CompanyGiftText>{t("Company gift")}</CompanyGiftText>
              <CompanyGiftContainer onChange={handleRadioChange}>
                <RadioContainer>
                  <input
                    type="radio"
                    id="radioYes"
                    name="companyGift"
                    value="yes"
                    defaultChecked={isCompanyGift}
                  />
                  <RadioLabel htmlFor="radioYes">{t("Yes")}</RadioLabel>
                </RadioContainer>
                <RadioContainer>
                  <input
                    type="radio"
                    id="radioNo"
                    defaultChecked={!isCompanyGift}
                    name="companyGift"
                    value="no"
                  />
                  <RadioLabel htmlFor="radioNo">{t("No")}</RadioLabel>
                </RadioContainer>
              </CompanyGiftContainer>
            </div>
            {isCompanyGift && (
              <Input
                label="Company Name"
                type="text"
                name="companyName"
                id="companyName"
                onChange={handleInputAndSelect}
                value={donorInfo.companyName}
                isError={formErrors.companyName}
                maxLength={200}
                isRequired
              />
            )}
          </>
        )}

        {currentForm === 1 && (
          <AddressComplete
            countryToLookup={donorInfo.country}
            addressType="donor"
          />
        )}
        <Input
          label="Address 2"
          type="text"
          name="addressLine2"
          id="addressLine2"
          onChange={handleInputAndSelect}
          value={donorInfo.addressLine2}
          maxLength={200}
        />
        <Input
          label="City"
          type="text"
          name="city"
          id="city"
          onChange={handleInputAndSelect}
          value={donorInfo.city}
          isError={formErrors.city}
          maxLength={50}
          isRequired
        />
        <InputRow>
          {donorInfo.country === "Canada" ||
          donorInfo.country === "United States" ? (
            <Select
              label="State / Province"
              name="provinceState"
              id="provinceState"
              onChange={handleInputAndSelect}
              value={donorInfo.provinceState}
              isError={formErrors.provinceState}
              optionsData={
                donorInfo.country === "Canada"
                  ? provincesCollection
                  : statesCollection
              }
              withTranslation={donorInfo.country === "Canada"}
              isRequired
            />
          ) : (
            <Input
              label="State / Province"
              type="text"
              name="provinceState"
              id="provinceState"
              onChange={handleInputAndSelect}
              isError={formErrors.provinceState}
              value={donorInfo.provinceState}
              maxLength={50}
              isRequired
            />
          )}
          <Input
            label="Postal"
            type="text"
            name="postal"
            id="postal"
            onChange={handleInputAndSelect}
            value={donorInfo.postal}
            isError={formErrors.postal}
            maxLength={7}
            isRequired
          />
          <Select
            label="Country"
            name="country"
            id="country"
            onChange={handleInputAndSelect}
            value={donorInfo.country}
            isError={formErrors.country}
            optionsData={countriesCollection}
            isRequired
          />
        </InputRow>
        <Input
          label="Phone Number"
          type="phone"
          maxLength={50}
          name="phoneNumber"
          id="phoneNumber"
          onChange={handlePhoneNumber}
          value={donorInfo.phoneNumber}
          isError={formErrors.phoneNumber}
        />
        {isCCFDForm && (
          <CallCenterEmailDisclaimer data-cy="callCentreReceiptBlurb">
            {t("Receipts blurb")}
          </CallCenterEmailDisclaimer>
        )}
        {(isCCFDForm || isDevelopmentForm || Boolean(authenticationToken)) &&
          emailElement}

        {(isCCFDForm || isDevelopmentForm || isGPForm) && (
          <>
            {!isGPForm && checkboxElement}

            {!isFDForm && (
              <Input
                label={getCCFieldLabel()}
                type="text"
                name="callCentreNumber"
                id="callCentreNumber"
                value={callCentreNumber}
                maxLength={255}
                onChange={handleCallCenterNumber}
                isError={formErrors.callCentreNumber}
                isRequired={!isDevelopmentForm}
              />
            )}
          </>
        )}

        {/* NOTE: This will be added as phase 2 */}
        {/* {isDevelopmentForm && (
          <InputContainer>
            <label htmlFor="giftNotes">{t("Your personal message")}</label>
            <TextArea
              name="giftNotes"
              id="giftNotes"
              // onChange={handleTributePersonalMessageChange}
              value={giftNotes}
              maxLength={255}
            />
            <TextAreaCharLimit>
              <span>{giftNotes.length}</span>/<span>255</span>
            </TextAreaCharLimit>
          </InputContainer>
        )} */}
      </div>
      <BottomButtonsContainer>
        <BackButton />

        <NextStepButton onClick={handleNextForm}>
          {t("Next Step")}
        </NextStepButton>
      </BottomButtonsContainer>
    </FormPartContainer>
  );
};

export default DonorInfoStep;
