import React, { useState, useEffect } from "react";
import {
  selectAppealCode,
  selectCallCentreNumber,
  selectCoverTransactionFee,
  selectDonationAmount,
  selectDonationInfo,
  selectDonationType,
  selectDonorInfo,
  selectFormLanguage,
  selectHonourCardType,
  selectHonourType,
  selectIsCompanyGift,
  selectIsDuplicateEcard,
  selectIsPrintReceipt,
  selectLoyaltyCardNumber,
  selectPaymentMethod,
  selectSource,
} from "redux/form/form.selector";
import {
  selectCurrentForm,
  selectPopup,
  selectPresValue,
  selectSession,
} from "redux/helpers/helpers.selector";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import ClipLoader from "react-spinners/ClipLoader";
import { SectionHeading, FormPartContainer } from "global";
import { FinalMessage } from "./payment-step.styles";
import { useTranslation } from "react-i18next";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import {
  updateIsCheckoutCallError,
  updateIsFormDisabled,
  updateOpportunityId,
  updatePopup,
} from "redux/helpers/helpers.reducer";
import { nanoid } from "nanoid";
import { selectPresId } from "redux/presValue/presValue.selector";
import serviceCallsAPI from "utils/serviceCallsAPI";
import { CheckoutBody, FormSourceType } from "utils/interfaces";
import { popupMessages, primaryColor } from "utils/variables";
import {
  updateDebitAccountDetails,
  updatePaymentMethod,
} from "redux/form/form.reducer";
import CheckoutForm from "components/checkout-form/checkout-form.component";
import { TypeButton } from "components/donation-info-form/donation-info-form.styles";
import OverlayHeadingNavigation from "components/overlay-heading-navigation/overlay-heading-navigation.component";
import {
  getMonthlyWithdrawalDatePostfix,
  prepareTributeNameValuesForCheckout,
} from "utils/helper-functions";

const Payment: React.FC = () => {
  // GLOBAL STATE
  const currentForm = useAppSelector(selectCurrentForm);
  const donationType = useAppSelector(selectDonationType);
  const donationAmount = useAppSelector(selectDonationAmount);
  const honourType = useAppSelector(selectHonourType);
  const isCompanyGift = useAppSelector(selectIsCompanyGift);
  const donorInfo = useAppSelector(selectDonorInfo);
  const donationInfo = useAppSelector(selectDonationInfo);
  const presid = useAppSelector(selectPresId);
  const language = useAppSelector(selectFormLanguage);
  const coverFees = useAppSelector(selectCoverTransactionFee);
  const sendTributeCopy = useAppSelector(selectIsDuplicateEcard);
  const honourCardType = useAppSelector(selectHonourCardType);
  const source = useAppSelector(selectSource);
  const paymentMethod = useAppSelector(selectPaymentMethod);
  const loyaltyCardNumber = useAppSelector(selectLoyaltyCardNumber);
  let appealCode = useAppSelector(selectAppealCode);
  const presValue = useAppSelector(selectPresValue);
  const isPrintReceipt = useAppSelector(selectIsPrintReceipt);
  const callCentreNumber = useAppSelector(selectCallCentreNumber);
  const { sessionID } = useAppSelector(selectSession);
  const popup = useAppSelector(selectPopup);
  const dispatch = useAppDispatch();
  const {
    honoureeName: tributeName,
    honoureeFirstName,
    honoureeLastName,
    recipientFirstName,
    recipientLastName,
    cardRecipientFullName,
    cardRecipientTitle,
    emailSubject: tributeSubject,
    isTributeGift,
    personalMessage: tributeNote,
    recipientEmail: tributeEmail,
    cardActive: tributeEcardId,
  } = donationInfo;

  // LOCAL STATE
  const [clientSecret, setClientSecret] = useState<string>();
  const [paymentID, setPaymentID] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [dateMonthlyWithdrawal, setDateMonthlyWithdrawal] = useState(0);
  const [monthlyWithdrawalMilliseconds, setMonthlyWithdrawalMilliseconds] =
    useState<Date>();
  const [idempotencyKey, setIdempotencyKey] = useState<string | undefined>(
    undefined,
  );
  const [oldIdempotencyKey, setOldIdempotencyKey] = useState<
    string | undefined
  >(undefined);
  const [stripePromise, setStripePromise] = useState<
    Stripe | PromiseLike<Stripe | null> | null
  >();
  const { t } = useTranslation();
  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;
  const isDevelopmentForm = source === FormSourceType.DEVELOPMENT_TEAM;
  const isDevelopmentDonorForm =
    source === FormSourceType.DEVELOPMENT_TEAM_DONOR;
  const currentFormId = isOverlayForm ? 3 : 2;

  const paymentCheckout = async () => {
    setIsLoading(true);
    if (presValue === "default") {
      const attributionResp = await serviceCallsAPI.callAttribution(
        encodeURIComponent(donorInfo.email),
      );

      if (attributionResp.status === 200) {
        const appealData = await attributionResp.json();
        appealCode = appealData.appeal ? appealData.appeal : appealCode;
      }
    }

    const tributeNames = prepareTributeNameValuesForCheckout(isTributeGift, {
      tributeName,
      honoureeFirstName,
      honoureeLastName,
      cardRecipientFullName,
      recipientFirstName,
      recipientLastName,
    });
    const requestBody: CheckoutBody = {
      amount: donationAmount,
      ...donorInfo,
      presid,
      appealCode,
      giftType: donationType === "one-time" ? "OTG" : "PA",
      donationType: !isCompanyGift ? "Individual" : "Company",
      language,
      tributeSubject: (isTributeGift && tributeSubject) || undefined,
      tributeRecipientTitle: (isTributeGift && cardRecipientTitle) || undefined,
      ...tributeNames,
      tributeNote: (isTributeGift && tributeNote) || undefined,
      tributeEmail: (isTributeGift && tributeEmail) || undefined,
      tributeAddress:
        (isTributeGift &&
          honourCardType === "mail" &&
          `${donationInfo.honoureeAddressLine}, ${donationInfo.honoureeCity}, ${donationInfo.honoureeProvinceState}, ${donationInfo.honoureeCountry}, ${donationInfo.honoureePostal}`) ||
        undefined,
      tributeType: (isTributeGift && honourType) || undefined,
      tributeEcardId: tributeEcardId || undefined,
      coverFees,
      isPrintReceipt,
      doNotReceipt: isDevelopmentForm || isDevelopmentDonorForm,
      emailTributeEcard:
        isTributeGift && honourCardType === "ecard" ? true : false,
      mailTributeEcard:
        isTributeGift && honourCardType === "mail" ? true : false,
      sendTributeCopy,
      callCentreNumber: callCentreNumber.trim() || undefined,
      loyaltyCardNumber: loyaltyCardNumber || undefined,
      paymentMethod,
      source,
      sessionid: sessionID,
      utcOffset: donationInfo.utcOffset,
    };

    let checkoutResp;
    // Check what call needs to be made either to create or update Payment
    if (paymentID) {
      // If idempotency key didn't change (nothing on the form changed)
      // don't call update
      if (oldIdempotencyKey !== idempotencyKey && idempotencyKey) {
        checkoutResp = await serviceCallsAPI.callCheckoutUpdate(
          idempotencyKey,
          requestBody,
          paymentID,
        );
      } else {
        setIsLoading(false);
        dispatch(updateIsFormDisabled(false));
        return;
      }
    } else {
      // Call checkout when no paymentId
      checkoutResp = await serviceCallsAPI.callCheckout(
        idempotencyKey || "",
        requestBody,
      );
    }

    if (checkoutResp.status !== 200) {
      setIsLoading(false);
      dispatch(updateIsFormDisabled(false));

      dispatch(
        updatePopup({
          ...popup,
          isError: true,
          isActive: true,
          message:
            checkoutResp.status === 429
              ? popupMessages.cantProcessRequest
              : popupMessages.technicalDifficulties,
          isLoading: false,
        }),
      );

      dispatch(updateIsCheckoutCallError(true));
      return;
    }

    dispatch(updateIsCheckoutCallError(false));
    const checkoutData = await checkoutResp?.json();

    if (checkoutData) {
      setClientSecret(checkoutData.client_secret);
      setPaymentID(checkoutData.id);
      setOldIdempotencyKey(idempotencyKey);
      setIsLoading(false);
      dispatch(updateOpportunityId(checkoutData.opportunity_id));
      dispatch(updateIsFormDisabled(false));

      // GET MONTHLY WITHDRAWAL DATE
      const monthlyDate = new Date(checkoutData.next_payment_date * 1000);
      setDateMonthlyWithdrawal(monthlyDate.getUTCDate());
      setMonthlyWithdrawalMilliseconds(monthlyDate);
    }
  };

  // Stripe promise loading with dynamic keys
  useEffect(() => {
    if (currentForm === currentFormId) {
      setStripePromise(
        loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
          locale: language,
        }),
      );
    }
  }, [currentForm, language]); // eslint-disable-line

  useEffect(() => {
    if (currentForm === currentFormId) {
      // Create PaymentIntent as soon as the page loads
      dispatch(updateIsFormDisabled(true));

      try {
        paymentCheckout();
      } catch (error) {
        updatePopup({
          ...popup,
          isActive: true,
          isError: true,
          message: popupMessages.somethingWentWrong,
          isLoading: false,
        });
      }
    }
  }, [currentForm, language, paymentMethod, idempotencyKey]); // eslint-disable-line

  useEffect(() => {
    setIdempotencyKey(nanoid());
  }, [donorInfo, donationInfo]); // eslint-disable-line

  const handlePaymentMethodClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    const value = e.currentTarget.value;
    if (value === "card" || value === "direct_debit")
      dispatch(updatePaymentMethod(value));

    // Clear debit fields
    dispatch(
      updateDebitAccountDetails({
        accountNumber: "",
        transitNumber: "",
        verifyAccountNumber: "",
        institutionNumber: "",
      }),
    );
  };

  return (
    <FormPartContainer
      isOverlayForm={isOverlayForm}
      currentForm={currentForm}
      formID={currentFormId}
    >
      {isOverlayForm ? (
        <OverlayHeadingNavigation isWithButton headingText="Payment details" />
      ) : (
        <>
          <SectionHeading>{t("Complete payment info")}</SectionHeading>
          <FinalMessage>
            {t("You will be making a")}{" "}
            <span>
              {t((donationType + "-payment") as string)}
              {isTributeGift && ` ${t(honourType + "-payment")}`}
            </span>{" "}
            {t("donation of")}{" "}
            <span>
              {language === "en" && "$"}
              {donationAmount}
              {language === "fr" && " $"}
            </span>
            {donationType === "monthly" && paymentMethod === "card"
              ? t("starting today")
              : "."}
          </FinalMessage>
          {presValue === "VISA" && (
            <img
              src={process.env.REACT_APP_PREPAID_CARD_IMAGE}
              alt="Visa Card"
            />
          )}

          {donationType === "monthly" && !isLoading && (
            <>
              <FinalMessage>
                {t("Your monthly gift process", {
                  date: dateMonthlyWithdrawal,
                  afterNumber: getMonthlyWithdrawalDatePostfix(
                    dateMonthlyWithdrawal,
                  ),
                  fullDate: monthlyWithdrawalMilliseconds
                    ?.toISOString()
                    .split("T")[0],
                })}
              </FinalMessage>
            </>
          )}
        </>
      )}

      {donationType === "monthly" && (
        <div
          data-cy="paymentTypeButtonsContainer"
          style={{
            display: "flex",
            marginBottom: isOverlayForm ? "32px" : "20px",
          }}
        >
          <TypeButton
            value="card"
            isSelected={paymentMethod === "card"}
            onClick={handlePaymentMethodClick}
            isOverlayForm={isOverlayForm}
          >
            {t("Credit Card")}
          </TypeButton>
          <TypeButton
            value="direct_debit"
            isSelected={paymentMethod === "direct_debit"}
            onClick={handlePaymentMethodClick}
            isOverlayForm={isOverlayForm}
          >
            {t("Bank Withdrawal")}
          </TypeButton>
        </div>
      )}

      {isLoading || !paymentID ? (
        <div style={{ margin: "100px auto", textAlign: "center" }}>
          <ClipLoader color={primaryColor} loading={isLoading} size={70} />
        </div>
      ) : (
        <CheckoutForm
          clientSecret={clientSecret}
          stripePromise={stripePromise}
          paymentID={paymentID}
          dateMonthlyWithdrawal={dateMonthlyWithdrawal}
          monthlyWithdrawalMilliseconds={monthlyWithdrawalMilliseconds}
        />
      )}
    </FormPartContainer>
  );
};

export default Payment;
