import { FC, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  selectDonationAmount,
  selectDonationType,
  selectDonorInfo,
  selectFormLanguage,
  selectIsCompanyGift,
  selectLoyaltyCardNumber,
  selectSource,
} from "redux/form/form.selector";
import {
  updateAuthenticationToken,
  updatePopup,
  updateSession,
} from "redux/helpers/helpers.reducer";
import {
  selectAuthenticationToken,
  selectPopup,
  selectSession,
} from "redux/helpers/helpers.selector";
import { getSessionStartTime, sendCartAbandon } from "utils/helper-functions";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { FormSourceType } from "utils/interfaces";
import serviceCallsAPI from "utils/serviceCallsAPI";
import { popupMessages } from "utils/variables";

export const SessionManager: FC = () => {
  // LOCAL STATE
  const lastActiveTime = useRef(Date.now());
  const [refreshTime, setRefreshTime] = useState<number>();

  // GLOBAL STATE
  const session = useAppSelector(selectSession);
  const popup = useAppSelector(selectPopup);
  const donorInfo = useAppSelector(selectDonorInfo);
  const isCompanyGift = useAppSelector(selectIsCompanyGift);
  const donationAmount = useAppSelector(selectDonationAmount);
  const formLanguage = useAppSelector(selectFormLanguage);
  const loyaltyCardNumber = useAppSelector(selectLoyaltyCardNumber);
  const donationType = useAppSelector(selectDonationType);
  const source = useAppSelector(selectSource);
  const authenticationToken = useAppSelector(selectAuthenticationToken);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;
  const isMemorialGivingForm = source === FormSourceType.MEMORIAL_GIVING_FORM;

  // Handle page visibility change
  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      if (session.isActive) {
        if (!isMemorialGivingForm) {
          sendCartAbandon(
            donorInfo,
            isCompanyGift,
            session,
            donationAmount,
            formLanguage,
            loyaltyCardNumber,
            donationType,
            source,
          );
        }

        dispatch(updateSession({ ...session, isActive: false }));
        lastActiveTime.current = Date.now();
      }
    } else {
      const timeSinceLastActive =
        Math.round((Date.now() - lastActiveTime.current) / 1000) * 1000;
      const newSessionMilliseconds =
        session.sessionMilliseconds - timeSinceLastActive;

      if (newSessionMilliseconds > 0) {
        if (!isMemorialGivingForm) {
          sendCartAbandon(
            donorInfo,
            isCompanyGift,
            { ...session, startDate: getSessionStartTime() },
            donationAmount,
            formLanguage,
            loyaltyCardNumber,
            donationType,
            source,
          );
        }

        dispatch(
          updateSession({
            ...session,
            isActive: true,
            sessionMilliseconds: newSessionMilliseconds,
          }),
        );
      } else if (
        session.sessionMilliseconds > 0 // This is only to check if the session was active at the time of tab switch
      ) {
        handleSessionTimeout();
      }
    }
  };

  const handleSessionExtend = () => {
    if (session.isActive && !(popup.isActive && popup.isSession))
      dispatch(
        updateSession({
          ...session,
          startDate: getSessionStartTime(),
          sessionMilliseconds:
            +process.env.REACT_APP_SESSION_TIMEOUT_MILLISECONDS,
        }),
      );
  };

  const handleSessionTimeout = async () => {
    if (authenticationToken) {
      window.location.href = "/.auth/logout";
      return;
    }

    // Display reload popup
    if (!isOverlayForm)
      dispatch(
        updatePopup({
          isActive: true,
          isError: false,
          message: popupMessages.sessionTimeout,
          isLoading: false,
          isSession: true,
        }),
      );
    dispatch(
      updateSession({
        ...session,
        sessionMilliseconds: 0,
        isActive: false,
      }),
    );

    if (!isMemorialGivingForm) {
      sendCartAbandon(
        donorInfo,
        isCompanyGift,
        session,
        donationAmount,
        formLanguage,
        loyaltyCardNumber,
        donationType,
        source,
      );
    }
  };

  const callGetAuthToken = async () => {
    try {
      const res = await serviceCallsAPI.callGetAuthToken();
      const data = await res.json();

      return data[0];
    } catch (err: unknown) {
      return;
    }
  };

  const callGetAuthTokenAndUpdateState = async () => {
    const authObject = await callGetAuthToken();
    if (!authObject || !authObject.access_token) return;

    const { access_token, expires_on } = authObject || {};

    const dateObject = new Date(expires_on);
    const currentTime = new Date();
    setRefreshTime(dateObject.getTime() - currentTime.getTime() - 120000); // 2 minute buffer

    if (access_token) dispatch(updateAuthenticationToken(access_token));
  };

  const refreshToken = async () => {
    try {
      await serviceCallsAPI.callRefreshToken();

      await callGetAuthTokenAndUpdateState();
    } catch (err: unknown) {
      return;
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleSessionExtend);
    document.addEventListener("keydown", handleSessionExtend);

    return () => {
      document.removeEventListener("click", handleSessionExtend);
      document.removeEventListener("keydown", handleSessionExtend);
    };
  }, [session]); // eslint-disable-line

  useEffect(() => {
    if (pathname !== "/error" && pathname !== "/thank-you") {
      const timeout = setTimeout(() => {
        const nextMilliseconds = session.sessionMilliseconds - 1000;
        if (session.isActive && session.sessionID) {
          if (nextMilliseconds <= 0) {
            handleSessionTimeout();
            return;
          } else if (
            nextMilliseconds <=
            +process.env.REACT_APP_SESSION_POPUP_DISPLAY_MILLISECONDS
          ) {
            if (!popup.isActive && !isOverlayForm) {
              // Display reminder popup
              dispatch(
                updatePopup({
                  isActive: true,
                  isError: false,
                  message: popupMessages.sessionTimeoutWarning,
                  isLoading: false,
                  isSession: true,
                }),
              );
            }
          }

          dispatch(
            updateSession({
              ...session,
              sessionMilliseconds: nextMilliseconds,
            }),
          );
        }
      }, 1000);

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        clearTimeout(timeout);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange,
        );
      };
    }
  }, [session, dispatch, pathname]); // eslint-disable-line

  useEffect(() => {
    callGetAuthTokenAndUpdateState();
  }, []);

  useEffect(() => {
    if (refreshTime) {
      const timeout = setTimeout(refreshToken, refreshTime);
      return () => clearTimeout(timeout);
    }
  }, [refreshTime]); // eslint-disable-line

  return null;
};

export default SessionManager;
