import Input from "components/input/input.component";
import OverlayHeadingNavigation from "components/overlay-heading-navigation/overlay-heading-navigation.component";
import {
  BottomButtonsContainer,
  FormPartContainer,
  NextStepButton,
} from "global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { updateDonorInfo, updateFormErrors } from "redux/form/form.reducer";
import { selectDonorInfo, selectFormErrors } from "redux/form/form.selector";
import { updateCurrentForm } from "redux/helpers/helpers.reducer";
import { selectCurrentForm } from "redux/helpers/helpers.selector";
import {
  scrollToTheError,
  scrollToTheTop,
  validate,
} from "utils/helper-functions";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { ValidationErrors } from "utils/interfaces";
import { overlayDonorInfoSchema } from "utils/validation-schemas";
import { emailRegex, nameRegex, phoneNumberRegex } from "utils/variables";
import { ValidationError } from "yup";

const OverlayDonorInfo: FC = () => {
  // GLOBAL STATE
  const currentForm = useAppSelector(selectCurrentForm);
  const donorInfo = useAppSelector(selectDonorInfo);
  const formErrors = useAppSelector(selectFormErrors);
  const dispatch = useAppDispatch();

  // LOCAL STATE
  const { t } = useTranslation();

  const handleNextForm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      await validate(
        {
          email: donorInfo.email,
          firstName: donorInfo.firstName,
          lastName: donorInfo.lastName,
          phoneNumber: donorInfo.phoneNumber,
        },
        overlayDonorInfoSchema,
      );
      dispatch(updateFormErrors({}));
      dispatch(updateCurrentForm(currentForm + 1));

      // Scroll to the top
      scrollToTheTop();
    } catch (error: unknown) {
      const errors: ValidationErrors = {};

      (error as ValidationError).inner.forEach((err) => {
        if (err.path) {
          errors[err.path] = err.message;
        }
      });

      scrollToTheError(Object.keys(errors)[0]);
      dispatch(updateFormErrors(errors));
    }
  };

  const handleInputAndSelect = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.currentTarget;

    if (["firstName", "lastName"].includes(name)) {
      if (nameRegex.test(value))
        dispatch(updateDonorInfo({ ...donorInfo, [name]: value }));
    } else if (name === "email") {
      if (!emailRegex.test(value)) return;
      // if (!presValueURL) dispatch(updatePresValue("default")); // QUESTION: Why needed this

      dispatch(updateDonorInfo({ ...donorInfo, email: value }));
    }
  };

  const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.currentTarget.value;

    if (phoneNumberRegex.test(targetValue))
      dispatch(updateDonorInfo({ ...donorInfo, phoneNumber: targetValue }));
  };

  return (
    <FormPartContainer currentForm={currentForm} formID={1} isOverlayForm>
      <OverlayHeadingNavigation isWithButton headingText="Enter your details" />

      <Input
        label="Email Address"
        type="email"
        name="email"
        id="email"
        onChange={handleInputAndSelect}
        value={donorInfo.email}
        isError={formErrors.email}
        maxLength={150}
        isRequired
      />
      <Input
        label="First Name"
        type="text"
        name="firstName"
        id="firstName"
        onChange={handleInputAndSelect}
        value={donorInfo.firstName}
        isError={formErrors.firstName}
        maxLength={50}
        isRequired
      />
      <Input
        label="Last Name"
        type="text"
        name="lastName"
        id="lastName"
        onChange={handleInputAndSelect}
        value={donorInfo.lastName}
        isError={formErrors.lastName}
        maxLength={100}
        isRequired
      />
      <Input
        label="Phone Number"
        type="phone"
        maxLength={50}
        name="phoneNumber"
        id="phoneNumber"
        onChange={handlePhoneNumber}
        value={donorInfo.phoneNumber}
        isError={formErrors.phoneNumber}
      />

      <BottomButtonsContainer isOverlayForm>
        <NextStepButton isOverlayForm onClick={handleNextForm}>
          {t("Continue")}
        </NextStepButton>
      </BottomButtonsContainer>
    </FormPartContainer>
  );
};

export default OverlayDonorInfo;
