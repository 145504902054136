import React from "react";

import DonationTypeStep from "components/donation-type-step/donation-type-step.component";
import DonorInfoStep from "components/donor-info-step/donor-info-step.component";
import Payment from "components/payment-step/payment-step.component";
import { FormContainer } from "./form.styles";
import OverlayDonorInfoStep from "components/overlay-donor-info-step/overlay-donor-info-step.component";
import { useAppSelector } from "utils/hooks";
import { selectSource } from "redux/form/form.selector";
import AddressInfoStep from "components/address-info-step/address-info-step.component";
import { FormSourceType } from "utils/interfaces";

interface Props {
  isContextual: boolean;
}

const Form: React.FC<Props> = ({ isContextual }) => {
  // GLOBAL STATE
  const source = useAppSelector(selectSource);

  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;

  return (
    <FormContainer isContextual={isContextual}>
      <DonationTypeStep />
      {isOverlayForm ? (
        <>
          <OverlayDonorInfoStep />
          <AddressInfoStep />
        </>
      ) : (
        <DonorInfoStep />
      )}
      <Payment />
    </FormContainer>
  );
};

export default Form;
