import styled, { CSSProperties } from "styled-components";
import {
  black,
  outlineColor,
  overlayFormBorderRadius,
  overlayFormBorderWidth,
  primaryColor,
  standardBorderRadius,
  standardBorderWidth,
} from "utils/variables";

interface TypeProps {
  isSelected: boolean;
  isOverlayForm?: boolean;
  areAsksMoreThanThree?: boolean;
}

export const TypeButton = styled.button(
  ({ isSelected, isOverlayForm }: TypeProps) => ({
    width: "100%",
    height: isOverlayForm ? "40px" : "59.5px",
    color: isOverlayForm ? black : primaryColor,
    transition: "background-color 0.4s, color 0.4s, border-color 0.4s",
    cursor: "pointer",
    border: `${isOverlayForm && !isSelected ? overlayFormBorderWidth : standardBorderWidth} solid ${isOverlayForm && !isSelected ? black : primaryColor}`,
    backgroundColor: "#fff",
    borderRadius: isOverlayForm
      ? overlayFormBorderRadius
      : standardBorderRadius,
    fontSize: isOverlayForm ? "15px" : "19px",
    fontWeight: isOverlayForm ? "normal" : 700,

    ...(isSelected && {
      backgroundColor: isOverlayForm ? "#fff" : primaryColor,
      color: isOverlayForm ? black : "#fff",
    }),

    "&:focus-visible": {
      outline: `solid ${outlineColor} 3px`,
    },

    "&:hover": {
      backgroundColor: isOverlayForm ? "#fff" : primaryColor,
      color: isOverlayForm ? black : "#fff",
      borderColor: primaryColor,
    },

    "&:not(:last-child)": {
      marginRight: isOverlayForm ? "8px" : "24px",

      ...(!isOverlayForm && {
        "@media only screen and (max-width: 500px)": {
          marginRight: "20px",
        },

        "@media only screen and (max-width: 320px)": {
          marginRight: "10px",
        },
      }),
    },

    ...(!isOverlayForm && {
      "@media only screen and (max-width: 550px)": {
        height: "57.5px",
        fontSize: "17px",
      },
    }),
  }),
);

export const AmountButtonsContainer = styled.div(
  ({ isOverlayForm }: { isOverlayForm: boolean }) => ({
    display: "flex",
    justifyContent: "space-between",

    /* Support for gap in modern browsers */
    "@supports (gap: 10px)": {
      gap: isOverlayForm ? "8px" : "20px 24px",

      "@media only screen and (max-width: 550px)": {
        gap: isOverlayForm ? "8px" : "20px 0",
      },
    },

    alignItems: "center",
    flexWrap: "wrap" as CSSProperties["flexWrap"],
  }),
);

export const AmountButton = styled.button(
  ({ isSelected, isOverlayForm, areAsksMoreThanThree }: TypeProps) => ({
    color: isOverlayForm ? black : primaryColor,
    transition: "background-color 0.4s, color 0.4s, border-color 0.4s",
    cursor: "pointer",
    border: `${isOverlayForm && !isSelected ? overlayFormBorderWidth : standardBorderWidth} solid ${isOverlayForm && !isSelected ? black : primaryColor}`,
    backgroundColor: "#fff",
    borderRadius: standardBorderRadius,
    fontSize: isOverlayForm ? "15px" : "24px",
    fontWeight: isOverlayForm ? "normal" : 700,
    width: isOverlayForm
      ? "calc(33.3% - 6px)"
      : areAsksMoreThanThree
        ? "calc(33.3% - 16px)"
        : "auto",
    padding: "0 17px",
    height: isOverlayForm ? "40px" : "65.5px",

    ...(isSelected && {
      backgroundColor: isOverlayForm ? "#fff" : primaryColor,
      color: isOverlayForm ? black : "#fff",
    }),

    "&:focus-visible": {
      outline: `solid ${outlineColor} 3px`,
    },

    "&:hover": {
      backgroundColor: isOverlayForm ? "#fff" : primaryColor,
      color: isOverlayForm ? black : "#fff",
      borderColor: primaryColor,
    },

    ...(!isOverlayForm && {
      "@media only screen and (max-width: 1306px)": {
        width: "30%",
      },

      "@media only screen and (max-width: 550px)": {
        padding: "0",
        height: "60.5px",
        fontSize: "20px",
      },
    }),
  }),
);

export const OtherInputContainer = styled.div(
  ({ isOverlayForm, isSelected }: TypeProps) => ({
    flex: 1,
    display: "flex",
    minWidth: "300px",
    borderRadius: isOverlayForm
      ? overlayFormBorderRadius
      : standardBorderRadius,

    input: {
      border: `${isOverlayForm ? overlayFormBorderWidth : standardBorderWidth} solid ${isOverlayForm && !isSelected ? black : primaryColor}`,
      borderLeft: 0,
      borderRadius: `0 ${isOverlayForm ? overlayFormBorderRadius : standardBorderRadius} ${isOverlayForm ? overlayFormBorderRadius : standardBorderRadius} 0`,
      width: "100%",
      padding: isOverlayForm ? "0 10px 0 3px" : "0 18px",
      fontSize: isOverlayForm ? "15px" : "24px",
      fontWeight: isOverlayForm ? "normal" : 700,
      color: isOverlayForm ? black : primaryColor,
    },

    ...(isOverlayForm
      ? {
          position: "relative" as CSSProperties["position"],
          "&::after": {
            content: "'CAD'",
            position: "absolute" as CSSProperties["position"],
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
          },
        }
      : {
          "&:focus-within": {
            outline: `solid ${outlineColor} 3px`,
          },
        }),

    "@media only screen and (max-width: 550px)": {
      width: "100%",
    },

    "@media only screen and (max-width: 320px)": {
      minWidth: "100%",
    },
  }),
);

export const OtherLabel = styled.label(
  ({ isSelected, isOverlayForm }: TypeProps) => ({
    fontSize: isOverlayForm ? "15px" : "19px",
    fontWeight: isOverlayForm ? "normal" : 700,
    border: `${isOverlayForm ? overlayFormBorderWidth : standardBorderWidth} solid ${isOverlayForm && !isSelected ? black : primaryColor}`,
    display: "flex",
    height: "100%",
    color: isOverlayForm ? black : primaryColor,
    padding: isOverlayForm ? "10px 0 10px 10px" : "20px",
    borderRadius: `${isOverlayForm ? overlayFormBorderRadius : standardBorderRadius} 0 0 ${isOverlayForm ? overlayFormBorderRadius : standardBorderRadius}`,
    cursor: "pointer",
    borderRight: isOverlayForm ? 0 : "auto",
    minWidth: "fit-content",

    ".dollar-sign": {
      marginLeft: "15px",
    },

    ...(isSelected && {
      backgroundColor: isOverlayForm ? "#fff" : primaryColor,
      color: isOverlayForm ? black : "#fff",
    }),

    "@media only screen and (max-width: 550px)": {
      fontSize: isOverlayForm ? "15px" : "17px",
    },
  }),
);

export const CoverFeeText = styled.p(
  ({ isOverlayForm }: { isOverlayForm: boolean }) => ({
    marginBottom: "8px",
    fontSize: isOverlayForm ? "15px" : "18px",
    fontWeight: isOverlayForm ? "normal" : 700,

    "@media only screen and (max-width: 320px)": {
      fontSize: "14px",
    },
  }),
);
