import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { selectFormLanguage } from "redux/form/form.selector";
import { useAppSelector } from "utils/hooks";

const LanguageManager: FC = () => {
  const formLanguage = useAppSelector(selectFormLanguage);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(formLanguage);
  }, [formLanguage]); // eslint-disable-line

  return null;
};

export default LanguageManager;
