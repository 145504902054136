import AddressComplete from "components/address-complete/address-complete.component";
import Input from "components/input/input.component";
import OverlayHeadingNavigation from "components/overlay-heading-navigation/overlay-heading-navigation.component";
import Select from "components/select/select.component";
import {
  BottomButtonsContainer,
  FormPartContainer,
  NextStepButton,
  TwoColumnsInput,
} from "global";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { updateDonorInfo, updateFormErrors } from "redux/form/form.reducer";
import { selectDonorInfo, selectFormErrors } from "redux/form/form.selector";
import { updateCurrentForm } from "redux/helpers/helpers.reducer";
import { selectCurrentForm } from "redux/helpers/helpers.selector";
import {
  scrollToTheError,
  scrollToTheTop,
  validate,
} from "utils/helper-functions";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { ValidationErrors } from "utils/interfaces";
import { overlayAddressInfoSchema } from "utils/validation-schemas";
import {
  addressRegex,
  countriesCollection,
  postalCodeRegex,
  provincesCollection,
  statesCollection,
} from "utils/variables";
import { ValidationError } from "yup";

const AddressInfoStep: FC = () => {
  // GLOBAL STATE
  const currentForm = useAppSelector(selectCurrentForm);
  const donorInfo = useAppSelector(selectDonorInfo);
  const formErrors = useAppSelector(selectFormErrors);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleNextForm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      await validate(
        {
          addressLine1: donorInfo.addressLine1,
          city: donorInfo.city,
          country: donorInfo.country,
          postal: donorInfo.postal,
          provinceState: donorInfo.provinceState,
        },
        overlayAddressInfoSchema,
      );
      dispatch(updateFormErrors({}));
      dispatch(updateCurrentForm(currentForm + 1));

      // Scroll to the top
      scrollToTheTop();
    } catch (error: unknown) {
      const errors: ValidationErrors = {};

      (error as ValidationError).inner.forEach((err) => {
        if (err.path) {
          errors[err.path] = err.message;
        }
      });

      scrollToTheError(Object.keys(errors)[0]);
      dispatch(updateFormErrors(errors));
    }
  };

  const handleInputAndSelect = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.currentTarget;

    if (name === "country") {
      dispatch(
        updateDonorInfo({ ...donorInfo, provinceState: "", [name]: value }),
      );
    } else {
      if (name === "postal" && !postalCodeRegex.test(value)) {
        return;
      } else if (!addressRegex.test(value)) {
        return;
      }

      dispatch(
        updateDonorInfo({
          ...donorInfo,
          [name]: name === "addressLine2" ? value.replace(/=/g, "") : value,
        }),
      );
    }
  };

  return (
    <FormPartContainer currentForm={currentForm} formID={2} isOverlayForm>
      <OverlayHeadingNavigation isWithButton headingText="Enter your details" />

      {currentForm === 2 && (
        <AddressComplete
          countryToLookup={donorInfo.country}
          addressType="donor"
        />
      )}

      <Input
        label="Address 2"
        type="text"
        name="addressLine2"
        id="addressLine2"
        onChange={handleInputAndSelect}
        value={donorInfo.addressLine2}
        maxLength={200}
      />
      <Input
        label="City"
        type="text"
        name="city"
        id="city"
        onChange={handleInputAndSelect}
        value={donorInfo.city}
        isError={formErrors.city}
        maxLength={50}
        isRequired
      />

      <TwoColumnsInput>
        {["Canada", "United States"].includes(donorInfo.country) ? (
          <Select
            label="State / Province"
            name="provinceState"
            id="provinceState"
            onChange={handleInputAndSelect}
            value={donorInfo.provinceState}
            isError={formErrors.provinceState}
            optionsData={
              donorInfo.country === "Canada"
                ? provincesCollection
                : statesCollection
            }
            withTranslation={donorInfo.country === "Canada"}
            isRequired
          />
        ) : (
          <Input
            label="State / Province"
            type="text"
            name="provinceState"
            id="provinceState"
            onChange={handleInputAndSelect}
            isError={formErrors.provinceState}
            value={donorInfo.provinceState}
            maxLength={50}
            isRequired
          />
        )}
        <Input
          label="Postal"
          type="text"
          name="postal"
          id="postal"
          onChange={handleInputAndSelect}
          value={donorInfo.postal}
          isError={formErrors.postal}
          maxLength={7}
          isRequired
        />
      </TwoColumnsInput>

      <Select
        label="Country"
        name="country"
        id="country"
        onChange={handleInputAndSelect}
        value={donorInfo.country}
        isError={formErrors.country}
        optionsData={countriesCollection}
        isRequired
      />

      <BottomButtonsContainer isOverlayForm>
        <NextStepButton
          isOverlayForm
          onClick={handleNextForm}
          data-cy="address-info-button"
        >
          {t("Continue")}
        </NextStepButton>
      </BottomButtonsContainer>
    </FormPartContainer>
  );
};

export default AddressInfoStep;
