import { CheckboxContainer, SectionHeading } from "global";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AmountButton,
  AmountButtonsContainer,
  CoverFeeText,
  OtherInputContainer,
  OtherLabel,
  TypeButton,
} from "./donation-info-form.styles";
import MonthlySuggestion from "components/monthly-suggestion/monthly-suggestion.component";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  selectCoverTransactionFee,
  selectDonationAmount,
  selectDonationInfo,
  selectFormErrors,
  selectFormLanguage,
  selectSource,
} from "redux/form/form.selector";
import {
  selectAskAmounts,
  selectIsMonthlySupported,
  selectIsOneTimeSupported,
} from "redux/presValue/presValue.selector";
import {
  selectIsCoverFeeSupported,
  selectMonthlySuggestionText,
} from "redux/helpers/helpers.selector";
import analyticsAPI from "utils/analyticsAPI";
import {
  updateCoverTransactionFee,
  updateDonationAmount,
  updateDonationType,
  updateFormErrors,
  updateIsTributeGift,
  updateLoyaltyCardNumber,
  updatePaymentMethod,
} from "redux/form/form.reducer";
import { updateAskAmounts } from "redux/presValue/presValue.reducer";
import {
  convertNumberToDecimal,
  handleAskAmountsUpdate,
} from "utils/helper-functions";
import { useSearchParams } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import ErrorMessage from "components/error-message/error-message.component";
import { FormSourceType } from "utils/interfaces";
import datalayerAPI from "utils/datalayerAPI";

interface Props {
  renderPointsCard: () => React.ReactNode;
}

const DonationInfoForm: FC<Props> = ({ renderPointsCard }) => {
  // URL SEARCH PARAMS
  const [searchParams] = useSearchParams();
  const askActive = searchParams.get("ask") || searchParams.get("s_Ask");
  const chosenAsk =
    searchParams.get("chosenAsk") || searchParams.get("s_chosenAsk");

  // GLOBAL STATE
  const formErrors = useAppSelector(selectFormErrors);
  const isMonthlySupported = useAppSelector(selectIsMonthlySupported);
  const isOneTimeSupported = useAppSelector(selectIsOneTimeSupported);
  const monthlySuggestionText = useAppSelector(selectMonthlySuggestionText);
  const isCoverFeeSupported = useAppSelector(selectIsCoverFeeSupported);
  const coverTransactionFee = useAppSelector(selectCoverTransactionFee);
  const formLanguage = useAppSelector(selectFormLanguage);
  const donationInfo = useAppSelector(selectDonationInfo);
  const askAmounts = useAppSelector(selectAskAmounts);
  const donationAmount = useAppSelector(selectDonationAmount);
  const source = useAppSelector(selectSource);
  const dispatch = useAppDispatch();

  // LOCAL STATE
  const [isOthersActive, setIsOthersActive] = useState(false);
  const [othersValue, setOthersValue] = useState<string>("");
  const [activeAskButton, setActiveAskButton] = useState<number>();
  const { t } = useTranslation();
  const appInsights = useAppInsightsContext();
  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;
  const isMemorialGivingForm = source === FormSourceType.MEMORIAL_GIVING_FORM;
  const areAsksMoreThanThree =
    askAmounts[donationInfo.donationType || "one-time"].length > 3;

  useEffect(() => {
    if (askActive) {
      if (+askActive <= 3 && +askActive > 0) {
        setActiveAskButton(+askActive);
        dispatch(
          updateDonationAmount(
            askAmounts[donationInfo.donationType || "one-time"][+askActive - 1],
          ),
        );
      } else if (+askActive === 4) {
        setActiveAskButton(0);
        setIsOthersActive(true);
      }
    }

    if (chosenAsk && !isNaN(+chosenAsk)) {
      +chosenAsk < 999999999
        ? setOthersValue(chosenAsk)
        : setOthersValue("999999999");
      dispatch(updateDonationAmount(+chosenAsk));
      setActiveAskButton(0);
      setIsOthersActive(true);
    }
  }, []); // eslint-disable-line

  const handleAmountClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const target = e.currentTarget;
    analyticsAPI.handleAskSelect(appInsights, e);
    if (target.dataset.id && activeAskButton !== +target.dataset.id) {
      dispatch(updateDonationAmount(+target.value));
      setIsOthersActive(false);
      setOthersValue("");
      setActiveAskButton(+target.dataset.id);

      // Fire event
      if (isOverlayForm) {
        datalayerAPI.handleDonationTypeValuesChange(
          "defaultGiftAmounts",
          target.value,
        );
      }
    }
  };

  const handleTypeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { value } = e.currentTarget;

    // Guard clause
    if (donationInfo.donationType === value) return;

    if (donationInfo.donationType && !isOthersActive) {
      handleResetAskAmount();
    } else {
      if (value !== "one-time" && !isOthersActive) {
        handleResetAskAmount();
      }
    }

    // Update type
    if (value === "monthly" || value === "one-time") {
      dispatch(updateDonationType(value));
      dispatch(updatePaymentMethod("card"));
    }

    // Fire event
    if (isOverlayForm) {
      datalayerAPI.handleDonationTypeValuesChange("giftFrequency", value);
    }

    // Collapse In Honour in case of monthly donation
    if (value === "monthly") dispatch(updateIsTributeGift(false));

    if (renderPointsCard()) {
      dispatch(updateLoyaltyCardNumber(""));
      dispatch(updateFormErrors({}));
    }
  };

  const handleResetAskAmount = () => {
    setActiveAskButton(0);
    dispatch(updateDonationAmount(0));
    dispatch(updateCoverTransactionFee(false));

    // Disable cover fees when type is changed
    if (coverTransactionFee) handleTransactionFee(undefined, false);
  };

  const handleTransactionFee = (
    e?: ChangeEvent | React.MouseEvent<HTMLButtonElement>,
    isAskActive = true,
  ) => {
    e?.preventDefault();

    // Guard clause against clicking on the same button on Overlay Form
    if (
      e &&
      "value" in e.currentTarget &&
      e.currentTarget.value === (coverTransactionFee ? "yes" : "no")
    ) {
      return;
    }

    // Update donation amount in the state
    if (isAskActive || othersValue)
      dispatch(
        updateDonationAmount(
          coverTransactionFee
            ? convertNumberToDecimal(donationAmount - 2)
            : convertNumberToDecimal(donationAmount + 2),
        ),
      );

    // Others field if it is active
    if (othersValue)
      setOthersValue(
        `${coverTransactionFee ? convertNumberToDecimal(+othersValue - 2) : convertNumberToDecimal(+othersValue + 2)}`,
      );

    // Update the ask amounts
    dispatch(
      updateAskAmounts({
        "one-time": handleAskAmountsUpdate(
          askAmounts,
          coverTransactionFee,
          "one-time",
        ),
        monthly: handleAskAmountsUpdate(
          askAmounts,
          coverTransactionFee,
          "monthly",
        ),
      }),
    );

    // Fire event
    if (isOverlayForm && e && "value" in e.currentTarget) {
      datalayerAPI.handleDonationTypeValuesChange(
        "feeCover",
        e?.currentTarget.value,
      );
    }

    dispatch(updateCoverTransactionFee(!coverTransactionFee));
  };

  const handleOtherInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value.length === 10) return;

    if (coverTransactionFee) {
      dispatch(updateCoverTransactionFee(!coverTransactionFee));
      handleTransactionFee();
    }

    // Allow only numeric values and a single dot
    const numericRegex = /^$|^\d+(\.\d{0,2})?$/;

    if (numericRegex.test(value)) {
      dispatch(updateDonationAmount(+value));
      setOthersValue(value);
      setActiveAskButton(0);
      setIsOthersActive(true);
    }
  };

  const handleOtherInputClick = () => {
    if (activeAskButton !== 0) {
      dispatch(updateDonationAmount(0));
      setIsOthersActive(true);
      setActiveAskButton(0);

      // Fire event
      if (isOverlayForm) {
        datalayerAPI.handleDonationTypeValuesChange(
          "defaultGiftAmounts",
          "other",
        );
      }
    }
  };

  return (
    <>
      {!isOverlayForm &&
        (isMemorialGivingForm ? (
          <SectionHeading>{t("Donate in memory of loved one")}</SectionHeading>
        ) : (
          <SectionHeading>{t("What type of gift")}</SectionHeading>
        ))}
      {monthlySuggestionText.en && monthlySuggestionText.fr && (
        <MonthlySuggestion />
      )}
      {!isMemorialGivingForm && (
        <div style={{ marginBottom: 32 }}>
          <div
            id="donationType"
            data-cy="typeButtonsContainer"
            style={{ display: "flex" }}
          >
            {isOneTimeSupported && (
              <TypeButton
                value="one-time"
                isSelected={donationInfo.donationType === "one-time"}
                onClick={handleTypeClick}
                isOverlayForm={isOverlayForm}
              >
                {t("one-time")}
              </TypeButton>
            )}

            {isMonthlySupported && (
              <TypeButton
                value="monthly"
                isSelected={donationInfo.donationType === "monthly"}
                onClick={handleTypeClick}
                isOverlayForm={isOverlayForm}
              >
                {t("monthly")}
              </TypeButton>
            )}
          </div>
          {formErrors.donationType && (
            <ErrorMessage errorMessage={formErrors.donationType} />
          )}
        </div>
      )}
      {!isOverlayForm && (
        <SectionHeading>{t("Choose gift amount")}:</SectionHeading>
      )}
      <div style={{ marginBottom: 32 }}>
        <AmountButtonsContainer
          id="donationAmount"
          data-cy="amountButtonsContainer"
          isOverlayForm={isOverlayForm}
        >
          {askAmounts[donationInfo.donationType || "one-time"].map(
            (ask, index) => (
              <AmountButton
                isSelected={activeAskButton === index + 1}
                isOverlayForm={isOverlayForm}
                data-id={index + 1}
                value={ask}
                key={index}
                onClick={handleAmountClick}
                areAsksMoreThanThree={areAsksMoreThanThree}
              >
                {isOverlayForm && formLanguage === "en" ? "$" : ""}
                {ask}
                {isOverlayForm && formLanguage === "fr" ? " $" : ""}
              </AmountButton>
            ),
          )}

          <OtherInputContainer
            data-cy="othersInputContainer"
            onClick={handleOtherInputClick}
            isOverlayForm={isOverlayForm}
            isSelected={isOthersActive}
          >
            <OtherLabel
              isSelected={isOthersActive}
              isOverlayForm={isOverlayForm}
              htmlFor="otherAmount"
            >
              <span>{t("Other")}:</span>
              {isOverlayForm && <span className="dollar-sign">$</span>}
            </OtherLabel>
            <input
              type="text"
              id="otherAmount"
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                analyticsAPI.handleOtherSelected(appInsights, e);
              }}
              onChange={handleOtherInputChange}
              value={othersValue}
            />
          </OtherInputContainer>
        </AmountButtonsContainer>
        {formErrors.donationAmount && (
          <ErrorMessage errorMessage={formErrors.donationAmount} />
        )}
      </div>

      {isCoverFeeSupported && (
        <div style={{ margin: "20px 0" }}>
          <CoverFeeText isOverlayForm={isOverlayForm}>
            {t(
              isOverlayForm
                ? "Would like cover fees"
                : "Did you know you can cover fees",
            )}
          </CoverFeeText>
          {isOverlayForm ? (
            <div style={{ display: "flex" }}>
              <TypeButton
                value="yes"
                isSelected={coverTransactionFee}
                onClick={(e) => handleTransactionFee(e)}
                isOverlayForm={isOverlayForm}
              >
                {t("Yes")}
              </TypeButton>
              <TypeButton
                value="no"
                isSelected={!coverTransactionFee}
                onClick={(e) => handleTransactionFee(e)}
                isOverlayForm={isOverlayForm}
              >
                {t("No")}
              </TypeButton>
            </div>
          ) : (
            <CheckboxContainer data-cy="feeCover">
              <input
                type="checkbox"
                id="feeCover"
                checked={coverTransactionFee}
                onChange={() => handleTransactionFee()}
              />
              <label htmlFor="feeCover">{t("Agree to cover fee")}</label>
            </CheckboxContainer>
          )}
        </div>
      )}
    </>
  );
};

export default DonationInfoForm;
