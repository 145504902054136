import styled from "styled-components";
import {
  outlineColor,
  primaryColor,
  standardBorderRadius,
} from "utils/variables";

interface Props {
  isOverlayForm?: boolean;
}

export const ThankYouTextContainer = styled.div`
  padding: 15% 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 1080px) {
    padding: 20px;
  }
`;

export const ThankYouTextOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

export const ThankYouText = styled.p<Props>(({ isOverlayForm }) => ({
  fontSize: isOverlayForm ? "28px" : "20px",
  color: isOverlayForm ? "#000" : "#333",
  fontWeight: "bold",
}));

export const TogetherText = styled.p`
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin: 30px 0;

  span {
    color: ${primaryColor};
  }

  @media only screen and (max-width: 450px) {
    margin: 15px 0;
  }
`;

export const ThankYouParagraph = styled.p`
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 400;
`;

export const SeeImpactLink = styled.a<Props>(({ isOverlayForm }) => ({
  textDecoration: "none",
  color: "white",
  backgroundColor: primaryColor,
  display: "block",
  width: isOverlayForm ? "289px" : "100%",
  padding: "17px 20px",
  fontSize: isOverlayForm ? "15px" : "19px",
  fontWeight: "bold",
  borderRadius: isOverlayForm ? "30px" : standardBorderRadius,
  textAlign: "center",

  ...(!isOverlayForm && {
    "&:not(:last-child)": {
      marginBottom: "20px",
    },

    "&:focus-visible": {
      outline: `solid ${outlineColor} 3px`,
    },
  }),
}));

export const EmailLink = styled.a`
  color: ${primaryColor};
  text-decoration: none;
  font-weight: 700;

  &:focus-visible {
    outline: solid ${outlineColor} 3px;
  }
`;

export const ThankYouButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  /* @supports (gap: 10px) {
    gap: 20px;
  } */
`;

export const ConfirmationNumber = styled.p<Props>(({ isOverlayForm }) => ({
  fontSize: isOverlayForm ? "17px" : "20px",
  color: isOverlayForm ? "#000" : "#333",
  fontWeight: isOverlayForm ? "#000" : 400,
  marginTop: isOverlayForm ? "6px" : "20px",
}));

export const BannerContainer = styled.div<Props>(({ isOverlayForm }) => ({
  marginTop: isOverlayForm ? 0 : "80px",

  img: {
    width: "100%",
    maxWidth: isOverlayForm ? "500px" : "100%",
    aspectRatio: "128 / 45",
  },
}));
