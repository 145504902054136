import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  HeadingContainer,
  StyledHeading,
} from "./overlay-heading-navigation.styles";
import BackArrow from "icons/back-arrow/back-arrow";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { selectCurrentForm } from "redux/helpers/helpers.selector";
import { updateCurrentForm } from "redux/helpers/helpers.reducer";

interface Props {
  isWithButton?: boolean;
  headingText: string;
}

const OverlayHeadingNavigation: FC<Props> = ({ isWithButton, headingText }) => {
  // GLOBAL STATE
  const currentForm = useAppSelector(selectCurrentForm);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    dispatch(updateCurrentForm(currentForm - 1));
  };

  return (
    <HeadingContainer isWithButton={Boolean(isWithButton)}>
      {isWithButton && (
        <button onClick={handleButtonClick} data-cy="back-button">
          <BackArrow />
        </button>
      )}

      <StyledHeading>{t(headingText)}</StyledHeading>
    </HeadingContainer>
  );
};

export default OverlayHeadingNavigation;
