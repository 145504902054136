import { FC, SelectHTMLAttributes } from "react";
import { InputContainer, RedStar } from "global";
import { FormSourceType, SelectOptionItem } from "utils/interfaces";
import { useTranslation } from "react-i18next";
import ErrorMessage from "components/error-message/error-message.component";
import { useAppSelector } from "utils/hooks";
import { selectSource } from "redux/form/form.selector";
import { StyledSelect } from "./select.styles";

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  optionsData: SelectOptionItem[];
  isError?: string;
  withTranslation?: boolean;
  withValueTranslation?: boolean;
  label: string;
  isRequired?: boolean;
  withoutColon?: boolean;
}

export const Select: FC<Props> = ({
  optionsData,
  withTranslation,
  withValueTranslation,
  label,
  id,
  isRequired,
  isError,
  withoutColon,
  ...props
}) => {
  // GLOBAL STATE
  const source = useAppSelector(selectSource);
  const { t } = useTranslation();

  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;

  return (
    <InputContainer isOverlayForm={isOverlayForm}>
      <label htmlFor={id}>
        {isRequired && <RedStar>*</RedStar>} {t(label)}
        {!withoutColon && ":"}
      </label>
      <StyledSelect
        id={id}
        isError={isError}
        isOverlayForm={isOverlayForm}
        {...props}
      >
        {optionsData.map((item) => (
          <option
            key={item.value}
            value={withValueTranslation ? t(item.value) : item.value}
          >
            {withTranslation ? t(item.name) : item.name}
          </option>
        ))}
      </StyledSelect>
      {isError && <ErrorMessage errorMessage={isError} />}
    </InputContainer>
  );
};

export default Select;
