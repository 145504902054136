import styled from "styled-components";
import { InputAndSelectProps } from "utils/interfaces";
import {
  black,
  outlineColor,
  overlayFormBorderRadius,
  primaryColor,
} from "utils/variables";

export const StyledInput = styled.input<InputAndSelectProps>(
  ({ isError, isOverlayForm }) => ({
    height: "40px",
    padding: "0 8px",
    fontSize: "20px",

    ...(isOverlayForm
      ? {
          borderRadius: overlayFormBorderRadius,
          fontSize: "15px",
          border: `1px solid ${black}`,
        }
      : {
          ...(isError && { border: `1px solid ${primaryColor}` }),

          "&:focus": {
            outline: `solid ${outlineColor} 2px`,
          },

          "@media only screen and (max-width: 550px)": {
            fontSize: "18px",
          },
        }),
  }),
);
