import { primaryColor } from "utils/variables";
import styled from "styled-components";

export const DoctorNameBox = styled.div`
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  width: 90%;
  padding: 15px 30px;
  text-align: center;
  background-color: ${primaryColor};
  font-size: 20px;
  color: #fff;
`;
