import * as yup from "yup";

import {
  AskAmounts,
  DebitInfoValues,
  DonationTypeValues,
  DonorInfoValidation,
  DonorInfoValues,
  OverlayAddressInfoValidation,
  OverlayDonorInfoValidation,
  Session,
  TSchemaShape,
} from "./interfaces";
import { useEffect, useState } from "react";
import serviceCallsAPI from "./serviceCallsAPI";

export const validate = async (
  values:
    | DonorInfoValidation
    | DonationTypeValues
    | DebitInfoValues
    | OverlayDonorInfoValidation
    | OverlayAddressInfoValidation,
  schemaShape: TSchemaShape,
) => {
  const schema = yup.object().shape(schemaShape || {});

  return schema.validate(values, { abortEarly: false });
};

export const scrollToTheTop = () => {
  const leftSection = document.getElementById("left-section");
  const navigation = document.getElementById("navigation");
  const header = document.getElementById("header");

  if (!leftSection || !navigation || !header) return;

  if (leftSection.scrollHeight > leftSection.clientHeight) {
    leftSection.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    window.scrollTo({
      top: navigation.offsetTop + header.offsetHeight,
      behavior: "smooth",
    });
  }
};

export const scrollToTheError = (errorFieldID: string) => {
  const leftSection = document.getElementById("left-section");
  const targetErrorElement = document.getElementById(errorFieldID);

  if (!leftSection || !targetErrorElement) return;

  if (leftSection.scrollHeight > leftSection.clientHeight) {
    leftSection.scrollTo({
      top: targetErrorElement.offsetTop,
      behavior: "smooth",
    });
  } else {
    window.scrollTo({
      top: targetErrorElement.offsetTop,
      behavior: "smooth",
    });
  }
};

export const handleAskAmountsUpdate = (
  askAmounts: AskAmounts,
  coverTransactionFee: boolean,
  askType: "one-time" | "monthly",
) =>
  askAmounts[askType].map((ask) => (coverTransactionFee ? ask - 2 : ask + 2));

export const calculateTimeInTheSessionLeft = (milliseconds: number) => {
  const minutes = Math.floor(milliseconds / 1000 / 60);
  const seconds = (milliseconds - minutes * 60 * 1000) / 1000;

  return `${minutes}:${seconds > 9 ? seconds : "0" + seconds}`;
};

export const useIsDesktop = () => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return viewportWidth > 500;
};

export const getSessionStartTime = () =>
  new Date().toISOString().split("T").join(" ");

export const sendCartAbandon = async (
  donorInfo: DonorInfoValues,
  isCompanyGift: boolean,
  session: Session,
  donationAmount: number,
  formLanguage: "en" | "fr",
  loyaltyCardNumber: string,
  donationType?: string,
  source?: string,
  isTransactionComplete = false,
) => {
  if (source === "hsf_test") return;

  const { firstName, lastName, email, companyName, postal, provinceState } =
    donorInfo;

  if (firstName && lastName && email && (isCompanyGift ? companyName : true)) {
    try {
      const response = await serviceCallsAPI.callSession({
        firstname: firstName,
        lastname: lastName,
        email,
        sessionid: session.sessionID,
        session_start_date: session.startDate,
        amount: donationAmount,
        language: formLanguage,
        organization_name: companyName || null,
        postal: postal || undefined,
        loyalty_card_number: loyaltyCardNumber
          ? "627421" + loyaltyCardNumber
          : undefined,
        gift_type: donationType,
        province: provinceState,
        transaction_complete: isTransactionComplete,
      });

      if (response.status !== 200)
        throw new Error("Error on sending session data");
    } catch (err: unknown) {
      console.error(
        err instanceof Error ? err.message : "Error on sending session data",
      );
    }
  }
};

export const getMonthlyWithdrawalDatePostfix = (
  dateMonthlyWithdrawal: number,
) => {
  switch (dateMonthlyWithdrawal) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

// this is a method that fixes the issue for some math operations
// e.g 2.06 + 2 returns 4.06000000006
export const convertNumberToDecimal = (value: number) => {
  return +value.toFixed(2);
};

export const prepareTributeNameValuesForCheckout = (
  isTributeGift: boolean,
  {
    tributeName,
    honoureeFirstName,
    honoureeLastName,
    cardRecipientFullName,
    recipientFirstName,
    recipientLastName,
  }: {
    tributeName: string;
    honoureeFirstName: string;
    honoureeLastName: string;
    cardRecipientFullName: string;
    recipientFirstName: string;
    recipientLastName: string;
  },
) => {
  if (isTributeGift) {
    const honoureeNameArray = tributeName.split(" ");
    const cardRecipientNameArray = cardRecipientFullName.split(" ");
    const hasRecipientData =
      cardRecipientFullName || recipientFirstName || recipientLastName;

    return {
      tributeName: tributeName || `${honoureeFirstName} ${honoureeLastName}`,
      tributeFirstname: honoureeFirstName || honoureeNameArray[0],
      tributeLastname:
        honoureeLastName || honoureeNameArray[1] || honoureeNameArray[0],
      tributeRecipientFullname: hasRecipientData
        ? cardRecipientFullName || `${recipientFirstName} ${recipientLastName}`
        : undefined,
      tributeRecipientFirstname:
        recipientFirstName || cardRecipientNameArray[0] || undefined,
      tributeRecipientLastname:
        recipientLastName ||
        cardRecipientNameArray[1] ||
        cardRecipientNameArray[0] ||
        undefined,
    };
  }
};
