import * as yup from "yup";
import "yup-phone-lite";
import { CountryCode } from "libphonenumber-js";
import { crmIDFinalValueRegex, nameRegex } from "./variables";

export const schemaConstructor = (
  schemaType: string,
  options: {
    loyaltyCardNumber?: string;
    locale?: CountryCode;
    presValue?: string;
    isInternalForm?: boolean;
    isAuthenticatedForm?: boolean;
    isGPForm?: boolean;
    isDevelopmentForm?: boolean;
  } = {},
) => {
  if (schemaType === "donationType") {
    const isRegularForm =
      !options.isInternalForm &&
      !options.isAuthenticatedForm &&
      !options.isGPForm &&
      !options.isDevelopmentForm;
    const sharedTributeFields = {
      recipientEmail: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "ecard",
          then: yup
            .string()
            .trim()
            .email("Provide valid email")
            .required("Enter recipient email"),
        }),
      }),
      emailSubject: yup
        .string()
        .trim()
        .when("isTributeGift", {
          is: true,
          then: yup.string().when("honourCardType", {
            is: "ecard",
            then: yup
              .string()
              .required(
                isRegularForm
                  ? "Provide email subject"
                  : "Provide ecard subject",
              ),
          }),
        }),
      cardRecipientFullName: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Enter full name"),
        }),
      }),
    };

    if (isRegularForm) {
      return {
        donationType: yup.string().required("Select gift type"),
        donationAmount: yup.number().moreThan(4.99, "More than 5"),
        honourType: yup.string().when("isTributeGift", {
          is: true,
          then: yup.string().required("Select honour gift type"),
        }),
        honoureeFirstName: yup
          .string()
          .trim()
          .when("isTributeGift", {
            is: true,
            then: yup.string().required("Enter honouree first name"),
          }),
        honoureeLastName: yup
          .string()
          .trim()
          .when("isTributeGift", {
            is: true,
            then: yup.string().required("Enter honouree last name"),
          }),
        recipientFirstName: yup
          .string()
          .trim()
          .when("isTributeGift", {
            is: true,
            then: yup.string().when("honourCardType", {
              is: (val: string) => val === "ecard",
              then: yup.string().required("Enter recipient first name"),
            }),
          }),
        recipientLastName: yup
          .string()
          .trim()
          .when("isTributeGift", {
            is: true,
            then: yup.string().when("honourCardType", {
              is: (val: string) => val === "ecard",
              then: yup.string().required("Enter recipient last name"),
            }),
          }),
        ...sharedTributeFields,
        cardActive: yup.number().when("isTributeGift", {
          is: true,
          then: yup.number().when("honourCardType", {
            is: (val: string) => val === "ecard",
            then: yup.number().moreThan(0, "Select card"),
          }),
        }),
        loyaltyCardNumber: options.loyaltyCardNumber
          ? yup.string().length(9, "Loyalty card equal 9")
          : yup.string(),
      };
    }
    return {
      donationType: yup.string().required("Select gift type"),
      donationAmount: yup.number().moreThan(4.99, "More than 5"),
      honourType: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().required("Select honour gift type"),
      }),
      honoureeName: yup
        .string()
        .trim()
        .when("isTributeGift", {
          is: true,
          then: yup.string().required("Enter honouree name"),
        }),
      ...sharedTributeFields,
      honoureeAddressLine: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Enter street address"),
        }),
      }),
      honoureeCity: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Enter city"),
        }),
      }),
      honoureePostal: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Enter postal"),
        }),
      }),
      honoureeProvinceState: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Select State / Province"),
        }),
      }),
      honoureeCountry: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Select country"),
        }),
      }),
      cardActive: yup.number().when("isTributeGift", {
        is: true,
        then: yup.number().when("honourCardType", {
          is: (val: string) => val === "mail" || val === "ecard",
          then: yup.number().moreThan(0, "Select card"),
        }),
      }),
      isTributeGift: yup.boolean(),
      honourCardType: yup.string(),
      loyaltyCardNumber: options.loyaltyCardNumber
        ? yup.string().length(9, "Loyalty card equal 9")
        : yup.string(),
    };
  } else if (schemaType === "donorInfo") {
    return {
      ...(options.isAuthenticatedForm || options.isInternalForm
        ? {
            companyName: yup.string().when("isCompanyGift", {
              is: true,
              then: yup.string().trim().required("Enter company name"),
            }),
          }
        : {}),
      ...(!options.isAuthenticatedForm && !options.isInternalForm
        ? {
            email: yup
              .string()
              .trim()
              .min(5, "Please provide email that is more than 5 characters")
              .max(150)
              .required(
                options.isGPForm
                  ? "Provide valid email or select checkbox"
                  : "Enter email",
              )
              .email("Provide valid email"),
          }
        : {}),
      firstName: yup
        .string()
        .trim()
        .required("Enter first name")
        .matches(nameRegex, "Enter first name")
        .min(1, "First name greater than 1")
        .max(50, "First name less than 50"),
      lastName: yup
        .string()
        .trim()
        .required("Enter last name")
        .matches(nameRegex, "Enter last name")
        .min(1, "Last name greater than 1")
        .max(100, "Last name less than 50"),
      ...(!options.isAuthenticatedForm && !options.isInternalForm
        ? {
            companyName: yup.string().when("isCompanyGift", {
              is: true,
              then: yup.string().trim().required("Enter company name"),
            }),
          }
        : {}),
      addressLine1: yup.string().trim().required("Enter street address"),
      city: yup.string().trim().required("Enter city"),
      provinceState: yup.string().trim().required("Select State / Province"),
      postal: yup.string().trim().required("Enter postal"),
      country: yup.string().trim().required("Select country"),
      phoneNumber: yup.string().test({
        name: "phone",
        test: function (value: string | undefined) {
          if (value !== "") {
            return (
              yup
                .string()
                .phone(options.locale)
                // .phone(["US", "CA", "GB", "AE", "FR"])
                .isValidSync(value)
            );
          }
          return true; // Skip validation if value is empty
        },
        message: "Invalid phone number",
      }),
      ...(options.isAuthenticatedForm || options.isInternalForm
        ? {
            email: yup
              .string()
              .trim()
              .min(5, "Please provide email that is more than 5 characters")
              .max(150)
              .required(
                options.isInternalForm
                  ? "Provide valid email or select checkbox"
                  : "Enter email",
              )
              .email("Provide valid email"),
          }
        : {}),
      callCentreNumber:
        options.presValue === "CC" || options.isGPForm
          ? yup
              .string()
              .trim()
              .required(
                options.isGPForm
                  ? "Enter Appeal Code"
                  : "Enter Call Centre Call Number",
              )
          : options.isDevelopmentForm &&
            yup
              .string()
              .trim()
              .test({
                name: "callCentreNumber",
                test: function (value: string | undefined) {
                  if (value && !crmIDFinalValueRegex.test(value)) {
                    return false;
                  }
                  return true; // Skip validation if value is empty
                },
                message: "Invalid CRM ID",
              }),
      isCompanyGift: yup.boolean(),
    };
  } else if (schemaType === "debit") {
    return {
      institutionNumber: yup.string().min(3, "Institution number 3 digits"),
      transitNumber: yup.string().min(5, "Transit number 5 digits"),
      accountNumber: yup.string().min(5, "Account number 5 digits"),
      verifyAccountNumber: yup
        .string()
        .min(5, "Verified account number 5 digits")
        .test(
          "accounts-match",
          "Account numbers do not match",
          function (value) {
            return value === this.resolve(yup.ref("accountNumber"));
          },
        ),
    };
  }
};

export const overlayDonorInfoSchema = {
  email: yup
    .string()
    .trim()
    .min(5, "Please provide email that is more than 5 characters")
    .max(150)
    .required("Enter email")
    .email("Provide valid email"),
  firstName: yup
    .string()
    .trim()
    .required("Enter first name")
    .matches(nameRegex, "Enter first name")
    .min(1, "First name greater than 1")
    .max(50, "First name less than 50"),
  lastName: yup
    .string()
    .trim()
    .required("Enter last name")
    .matches(nameRegex, "Enter last name")
    .min(1, "Last name greater than 1")
    .max(100, "Last name less than 50"),
  phoneNumber: yup.string().test({
    name: "phone",
    test: function (value: string | undefined) {
      if (value !== "") {
        return yup.string().phone("CA").isValidSync(value);
      }
      return true; // Skip validation if value is empty
    },
    message: "Invalid phone number",
  }),
};

export const overlayAddressInfoSchema = {
  addressLine1: yup.string().trim().required("Enter street address"),
  city: yup.string().trim().required("Enter city"),
  provinceState: yup.string().trim().required("Select State / Province"),
  postal: yup.string().trim().required("Enter postal"),
  country: yup.string().trim().required("Select country"),
};
