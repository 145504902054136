import { FC } from "react";
import ThankYouContentRegular from "./variants/thank-you-content.regular";
import { useAppSelector } from "utils/hooks";
import { selectFormLanguage } from "redux/form/form.selector";
import { useSearchParams } from "react-router-dom";
import analyticsAPI from "utils/analyticsAPI";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import ThankYouContentOverlay from "./variants/thank-you-content.overlay";
import { FormSourceType } from "utils/interfaces";
import { selectThankYouBanner } from "redux/presValue/presValue.selector";

interface Props {
  newDonationUrl: string;
  variant: "overlay" | "regular";
}

const ThankYouContent: FC<Props> = ({ newDonationUrl, variant }) => {
  // URL parameteres
  const [searchParams] = useSearchParams();
  const donorName = searchParams.get("name") || "";
  const confNumber = searchParams.get("confNumber") || "";
  const giftType = searchParams.get("giftType") || "";
  const province = searchParams.get("province")?.toLowerCase();
  const source = searchParams.get("source");

  // GLOBAL STATE
  const formLanguage = useAppSelector(selectFormLanguage);
  const thankYouBanner = useAppSelector(selectThankYouBanner);

  // Analytics
  const appInsights = useAppInsightsContext();

  const presThankYouBanner =
    thankYouBanner?.[formLanguage]?.[
      province === "on" && formLanguage == "en" ? "ON" : "default"
    ];
  const bannerLink =
    presThankYouBanner?.url ||
    (formLanguage === "en"
      ? province === "on"
        ? process.env.REACT_APP_THANK_YOU_PAGE_BANNER_LOTTERY_LINK_EN
        : process.env.REACT_APP_THANK_YOU_PAGE_BANNER_LINK_EN
      : process.env.REACT_APP_THANK_YOU_PAGE_BANNER_LINK_FR) ||
    "";
  const bannerImageLink =
    presThankYouBanner?.img ||
    (formLanguage === "en"
      ? province === "on"
        ? process.env.REACT_APP_THANK_YOU_PAGE_BANNER_LOTTERY_EN
        : process.env.REACT_APP_THANK_YOU_PAGE_BANNER_EN
      : process.env.REACT_APP_THANK_YOU_PAGE_BANNER_FR) ||
    "";
  const isDevelopmentForm =
    source === FormSourceType.DEVELOPMENT_TEAM ||
    source === FormSourceType.DEVELOPMENT_TEAM_DONOR;
  const isMemorialGivingForm = source === FormSourceType.MEMORIAL_GIVING_FORM;

  const handleBannerClick = () => {
    analyticsAPI.handleTYBannerClick(appInsights, {
      date: new Date().toLocaleDateString(),
      bannerLink,
      bannerImageLink,
    });
  };

  if (variant === "overlay") {
    return (
      <ThankYouContentOverlay
        donorName={donorName}
        confNumber={confNumber}
        bannerImageLink={bannerImageLink}
        bannerLink={bannerLink}
        handleBannerClick={handleBannerClick}
      />
    );
  }

  return (
    <ThankYouContentRegular
      donorName={donorName}
      confNumber={confNumber}
      giftType={giftType}
      newDonationUrl={newDonationUrl}
      bannerImageLink={bannerImageLink}
      bannerLink={bannerLink}
      isDevelopmentForm={isDevelopmentForm}
      isMemorialGivingForm={isMemorialGivingForm}
      handleBannerClick={handleBannerClick}
    />
  );
};

export default ThankYouContent;
