import { overlayStepNumberToNameMap } from "./variables";

class DatalayerAPI {
  handleCurrentFormChange(parameters: {
    currentForm: number;
    stepName: string;
  }) {
    window.dataLayer.push({
      event: "flowStep",
      stepNumber: parameters.currentForm + 1,
      stepName: parameters.stepName,
    });
  }

  handleValidationErrors(formErrorsFullNames: string[]) {
    window.dataLayer.push({
      event: "validationError",
      errorMessages: formErrorsFullNames,
    });
  }

  handleOverlayValidationErrors(stepNumber: number, stepName: string) {
    window.dataLayer.push({
      event: "flowError",
      stepNumber,
      stepName,
      donationFormType: "donation overlay",
    });
  }

  handleSuccessfulPayment(parameters: {
    donationAmount: number;
    donationType: "monthly" | "one-time" | undefined;
    paymentId: string;
    isCoverFees: boolean;
    honourType: string;
    honourCardType: string;
    isCompanyGift: boolean;
    isOverlayForm: boolean;
  }) {
    window.dataLayer.push({
      event: "purchase",
      transactionId: parameters.paymentId,
      revenue: parameters.donationAmount.toFixed(2),
      donationFrequency: parameters.donationType,
      donorCoveringFees: parameters.isCoverFees ? "yes" : "no",
      donationType: parameters.honourType ? parameters.honourType : "standard",
      cardType: parameters.honourCardType,
      isCompanyGift: parameters.isCompanyGift ? "yes" : "no",
      ...(parameters.isOverlayForm && { donationFormType: "donation overlay" }),
    });
  }

  handleOverlayStepImpression(stepNumber: number, stepName: string) {
    window.dataLayer.push({
      event: "flowStep",
      stepNumber,
      stepName,
      donationFormType: "donation overlay",
    });
  }

  handleDonationTypeValuesChange(fieldName: string, fieldValue: string) {
    window.dataLayer.push({
      event: "fieldChange",
      stepNumber: 1,
      stepName: overlayStepNumberToNameMap[0],
      fieldName,
      fieldValue,
      donationFormType: "donation overlay",
    });
  }
}

export default new DatalayerAPI();
