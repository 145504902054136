import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  LeftBlockContainer,
  RightBlockContainer,
  CampaignMemoContainer,
  MemoHeading,
  MemoText,
  InformationalBlurbContainer,
} from "./donation-page.styles";
import {
  googleApiLibraries,
  overlayStepNumberToNameMap,
  popupMessages,
  stepNumberToNameMap,
} from "utils/variables";

import Navigation from "components/navigation/navigation.component";
import Form from "components/form/form.component";
import Footer from "components/footer/footer.component";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  updateAskAmounts,
  updateContextualImage,
  updateIsHonourSupported,
  updateIsMemorySupported,
  updateIsMonthlySupported,
  updateIsOneTimeSupported,
  updateMonthlyAsk,
  updateOneTimeAsk,
  updatePresid,
  updateThankYouBanner,
  updateThankYouContextualImage,
} from "redux/presValue/presValue.reducer";
import {
  updateAppealCode,
  updateCardsCollectionInHonour,
  updateCardsCollectionInMemory,
  updateDonationInfo,
  updateDonationType,
  updateDonorInfo,
  updateFormLanguage,
  updateIsCompanyGift,
  updateIsTributeGift,
  updateSource,
} from "redux/form/form.reducer";
import {
  updateAssetsAvailable,
  updateContextualBoxHTML,
  updateFooterHTML,
  updateFormType,
  updateInformationalHTML,
  updateIsPointsCardSupported,
  updateIsCoverFeeSupported,
  updateIsPageError,
  updateMonthlySuggestionText,
  updatePopup,
  updatePresValue,
  updatePointsCardType,
  updateThankYouContextualBoxHTML,
} from "redux/helpers/helpers.reducer";
import {
  selectAppealCode,
  selectDonationInfo,
  selectDonorInfo,
  selectFormErrors,
  selectFormLanguage,
} from "redux/form/form.selector";
import { useLoadScript } from "@react-google-maps/api";
import {
  selectAuthenticationToken,
  selectContextualBoxHTML,
  selectCurrentForm,
  selectFormType,
  selectInformationalHTML,
  selectPopup,
  selectPresValue,
} from "redux/helpers/helpers.selector";
import serviceCallsAPI from "utils/serviceCallsAPI";
import analyticsAPI from "utils/analyticsAPI";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { PageContainer } from "global";
import ContextualImage from "components/contextual-image/contextual-image.component";
import { FormSourceType, LookupIdResponseData } from "utils/interfaces";
import ContImageSkeleton from "components/skeletons/cont-image-skeleton/cont-image-skeleton.component";
import FormSkeleton from "components/skeletons/form-skeleton/form-skeleton.component";
import LeftPartSkeleton from "components/skeletons/left-part-skeleton/left-part-skeleton.component";
import OverlayFormSkeleton from "components/skeletons/overlay-form-skeleton/overlay-form-skeleton.component";
import datalayerAPI from "utils/datalayerAPI";

interface Props {
  viewportHeight: number;
}

const DonationPage: React.FC<Props> = ({ viewportHeight }) => {
  // URL params
  const [searchParams] = useSearchParams();
  const formTypeURL = searchParams.get("type") || searchParams.get("s_fT");
  const presValueURL = searchParams.get("pres") || searchParams.get("s_pres");
  const source = searchParams.get("source") || searchParams.get("s_cscid");
  const lookupId = searchParams.get("lookupId");
  const cid = searchParams.get("cid");
  const locale = (
    searchParams.get("locale") || searchParams.get("s_locale")
  )?.toLowerCase();

  // GLOBAL STATE
  const donorInfo = useAppSelector(selectDonorInfo);
  const donationInfo = useAppSelector(selectDonationInfo);
  const contextualBoxHTML = useAppSelector(selectContextualBoxHTML);
  const informationalHTML = useAppSelector(selectInformationalHTML);
  const formLanguage = useAppSelector(selectFormLanguage);
  const appealCode = useAppSelector(selectAppealCode);
  const presValue = useAppSelector(selectPresValue);
  const currentForm = useAppSelector(selectCurrentForm);
  const formType = useAppSelector(selectFormType);
  const formErrors = useAppSelector(selectFormErrors);
  const popup = useAppSelector(selectPopup);
  const authenticationToken = useAppSelector(selectAuthenticationToken);

  // LOCAL STATE
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // HELPERS
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isContextual = formType === "cont";
  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;
  const isMemorialGivingForm = source === FormSourceType.MEMORIAL_GIVING_FORM;

  useEffect(() => {
    // istanbul ignore next
    if (source) dispatch(updateSource(source));
    // istanbul ignore next
    if (locale === "en" || locale === "en_ca")
      dispatch(updateFormLanguage("en"));
    // istanbul ignore next
    if (locale === "fr" || locale === "fr_ca")
      dispatch(updateFormLanguage("fr"));
    if (formTypeURL) dispatch(updateFormType(formTypeURL.toLowerCase()));

    if (!appealCode && !isLoading) {
      setIsLoading(true);

      serviceCallsAPI
        .callPresValue(presValueURL)
        .then((res) => res.json())
        .then((data) => {
          const {
            contextual_image_url_dt_en,
            contextual_image_url_dt_fr,
            contextual_image_url_m_en,
            contextual_image_url_m_fr,
            contextual_blurb_text_en,
            contextual_blurb_text_fr,
            informational_blurb_text_en,
            informational_blurb_text_fr,
            thankyou_blurb_text_en,
            thankyou_blurb_text_fr,
            footer_text_en,
            footer_text_fr,
            action_text_en,
            action_text_fr,
            thankyou_image_en,
            thankyou_image_fr,
            thankyou_banner_en,
            thankyou_banner_fr,
          } = data;

          if (contextual_image_url_dt_en) {
            dispatch(
              updateContextualImage({
                en: {
                  contextualImageDT: contextual_image_url_dt_en,
                  contextualImageM: contextual_image_url_m_en,
                },
                fr: {
                  contextualImageDT:
                    contextual_image_url_dt_fr || contextual_image_url_dt_en,
                  contextualImageM:
                    contextual_image_url_m_fr || contextual_image_url_m_en,
                },
              }),
            );
          }

          dispatch(updateIsMemorySupported(data.memory_supported));
          dispatch(updateIsHonourSupported(data.honour_supported));
          dispatch(updateIsOneTimeSupported(data.ot_supported));
          dispatch(updateIsMonthlySupported(data.monthly_supported));
          dispatch(updateIsPointsCardSupported(data.points_card_supported));
          dispatch(updateIsCoverFeeSupported(data.cover_fees_supported));
          dispatch(updatePresValue(data.pres_value.toUpperCase()));
          dispatch(updateAppealCode(data.appeal_code));
          dispatch(updatePresid(data.presid));

          if (data.points_card_supported)
            dispatch(updatePointsCardType(data.points_card_type));

          // Contextual and informational HTML
          if (contextual_blurb_text_en && contextual_blurb_text_fr) {
            dispatch(
              updateContextualBoxHTML({
                en: contextual_blurb_text_en,
                fr: contextual_blurb_text_fr,
              }),
            );
          }

          if (informational_blurb_text_en && informational_blurb_text_fr) {
            dispatch(
              updateInformationalHTML({
                en: informational_blurb_text_en,
                fr: informational_blurb_text_fr,
              }),
            );
          }

          // Footer text
          if (footer_text_en && footer_text_fr) {
            dispatch(
              updateFooterHTML({
                en: footer_text_en,
                fr: footer_text_fr,
              }),
            );
          }

          // Monthly Suggestion Text
          if (action_text_en && action_text_fr) {
            dispatch(
              updateMonthlySuggestionText({
                en: action_text_en,
                fr: action_text_fr,
              }),
            );
          }

          // TY Page dynamic content
          if (thankyou_blurb_text_en && thankyou_blurb_text_fr) {
            dispatch(
              updateThankYouContextualBoxHTML({
                en: thankyou_blurb_text_en,
                fr: thankyou_blurb_text_fr,
              }),
            );
          }

          if (thankyou_image_en) {
            dispatch(
              updateThankYouContextualImage({
                en: {
                  contextualImageDT: thankyou_image_en,
                },
                fr: {
                  contextualImageDT: thankyou_image_fr || thankyou_image_en,
                },
              }),
            );
          }

          if (thankyou_banner_en || thankyou_banner_fr) {
            const thankYouBannerEnObject =
              thankyou_banner_en && JSON.parse(thankyou_banner_en);
            const thankYouBannerFrObject =
              thankyou_banner_fr && JSON.parse(thankyou_banner_fr);
            dispatch(
              updateThankYouBanner({
                en: thankYouBannerEnObject,
                fr: thankYouBannerFrObject,
              }),
            );
          }

          // Preselecting gift type based on the value from pres service
          if (data.default_gift_type === "OTG") {
            dispatch(updateDonationType("one-time"));
          } else if (data.default_gift_type === "PA") {
            dispatch(updateDonationType("monthly"));
          } else if (data.default_gift_type === "TRO") {
            dispatch(updateIsTributeGift(true));
            dispatch(updateDonationType("one-time"));
          }

          // Ask amounts
          const monthlyAsks = data.monthly_donation_array
            .split(",")
            .map((ask: number) => +ask);
          const oneTimeAsks = data.one_time_donation_array
            .split(",")
            .map((ask: number) => +ask);
          dispatch(
            updateAskAmounts({
              monthly: monthlyAsks,
              "one-time": oneTimeAsks,
            }),
          );
          setIsLoading(false);
        })
        .catch(() => {
          dispatch(updateIsPageError(true));
          // Navigate to the error page
          navigate(`/error${window.location.search}`);
        });
    } else if (appealCode && lookupId && presValue && currentForm === 0) {
      setIsLoading(true);

      serviceCallsAPI
        .callLookupId(lookupId, presValue)
        .then((res) => res.json())
        .then(handleUpdatePersonalData)
        .finally(() => setIsLoading(false));
    } else if (appealCode && cid && authenticationToken && currentForm === 0) {
      setIsLoading(true);
      serviceCallsAPI
        .callCid(cid, authenticationToken)
        .then((res) => {
          if (!res.ok) return;

          return res.json();
        })
        .then(handleUpdatePersonalData)
        .finally(() => setIsLoading(false));
    }
  }, [appealCode, authenticationToken]); // eslint-disable-line

  // Get Ecards
  useEffect(() => {
    serviceCallsAPI
      .callAssets("in-honour")
      .then((res) => {
        if (res.status === 400) throw new Error();
        return res.json();
      })
      .then((data) => {
        dispatch(updateCardsCollectionInHonour(data));
      })
      // istanbul ignore next
      .catch(() => dispatch(updateAssetsAvailable(false)));

    serviceCallsAPI
      .callAssets("in-memory")
      .then((res) => {
        if (res.status === 400) throw new Error();
        return res.json();
      })
      .then((data) => {
        dispatch(updateCardsCollectionInMemory(data));
      })
      // istanbul ignore next
      .catch(() => dispatch(updateAssetsAvailable(false)));
  }, []); // eslint-disable-line

  useEffect(() => {
    const isDonationPage = window.location.pathname === "/";
    if (!isDonationPage) return;

    const stepName = isOverlayForm
      ? overlayStepNumberToNameMap[currentForm]
      : stepNumberToNameMap[currentForm];
    isOverlayForm
      ? datalayerAPI.handleOverlayStepImpression(currentForm + 1, stepName)
      : datalayerAPI.handleCurrentFormChange({ currentForm, stepName });
  }, [currentForm]);

  useEffect(() => {
    if (Object.entries(formErrors).length) {
      analyticsAPI.handleErrors({
        formErrors,
        appInsights,
        t,
        isOverlayForm,
        currentForm,
      });
    }
  }, [formErrors]); // eslint-disable-line

  useEffect(() => {
    if (isMemorialGivingForm)
      dispatch(
        updateDonationInfo({ ...donationInfo, honourType: "in-memory" }),
      );
  }, []);

  // Load Google Maps API
  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: googleApiLibraries,
  });

  const handleUpdatePersonalData = (data: LookupIdResponseData) => {
    if (Object.entries(data).length !== 0) {
      const {
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        country,
        email,
        city,
        language,
        phoneNumber,
        postal,
        provinceState,
        companyName,
        isorganization,
        mnt_ask_1,
        mnt_ask_2,
        mnt_ask_3,
        otg_ask_1,
        otg_ask_2,
        otg_ask_3,
      } = data;

      if (!locale) dispatch(updateFormLanguage(language));
      dispatch(
        updatePopup({
          ...popup,
          isError: false,
          isActive: true,
          message: popupMessages.welcomeBack,
          isLoading: false,
        }),
      );

      if (otg_ask_1 && otg_ask_2 && otg_ask_3)
        dispatch(updateOneTimeAsk([otg_ask_1, otg_ask_2, otg_ask_3]));
      if (mnt_ask_1 && mnt_ask_2 && mnt_ask_3)
        dispatch(updateMonthlyAsk([mnt_ask_1, mnt_ask_2, mnt_ask_3]));

      if (isorganization) {
        dispatch(updateIsCompanyGift(isorganization));
      }

      dispatch(
        updateDonorInfo({
          ...donorInfo,
          firstName: firstName || "",
          lastName: lastName || "",
          addressLine1: addressLine1 || "",
          addressLine2: addressLine2 || "",
          country: country || "Canada",
          email: email || "",
          city: city || "",
          phoneNumber: phoneNumber || "",
          postal: postal || "",
          provinceState: provinceState || "",
          companyName: companyName || "",
        }),
      );
    }
  };

  return (
    <PageContainer
      viewportHeight={viewportHeight}
      isOverlayForm={isOverlayForm}
    >
      {!appealCode ? (
        <>
          {isOverlayForm ? (
            <OverlayFormSkeleton />
          ) : (
            <LeftPartSkeleton isContextual={isContextual} />
          )}
          {isContextual && !isOverlayForm && <ContImageSkeleton />}
        </>
      ) : (
        <>
          <LeftBlockContainer
            isOverlayForm={isOverlayForm}
            id="left-section"
            isContextual={isContextual}
          >
            {!isContextual && informationalHTML[formLanguage] && (
              <InformationalBlurbContainer
                isVisa={presValueURL?.toUpperCase() === "VISA"}
                dangerouslySetInnerHTML={{
                  __html: informationalHTML[formLanguage],
                }}
              ></InformationalBlurbContainer>
            )}
            {isLoading ? (
              <FormSkeleton isContextual={isContextual} />
            ) : (
              <>
                {!isOverlayForm && <Navigation isContextual={isContextual} />}
                <main>
                  <Form isContextual={isContextual} />
                </main>
              </>
            )}
            {!isOverlayForm && <Footer />}
          </LeftBlockContainer>
          <RightBlockContainer isContextual={isContextual}>
            {appealCode && <ContextualImage />}
            {contextualBoxHTML[formLanguage] ? (
              <CampaignMemoContainer
                data-cy="redBox"
                dangerouslySetInnerHTML={{
                  __html: contextualBoxHTML[formLanguage],
                }}
              />
            ) : (
              <CampaignMemoContainer data-cy="redBox">
                <MemoHeading>{t("Beat as one")}</MemoHeading>
                <MemoText>
                  {t("Help Rally")}
                  <br />
                  <br />
                  {t("Give now")}
                </MemoText>
              </CampaignMemoContainer>
            )}
          </RightBlockContainer>
        </>
      )}
    </PageContainer>
  );
};
export default DonationPage;
