import React, { Suspense, lazy, useCallback } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import Aeroplan from "components/aeroplan/aeroplan.component";
import {
  selectDonationInfo,
  selectLoyaltyCardNumber,
  selectIsTributeGift,
  selectSource,
} from "redux/form/form.selector";
import {
  selectAssetsAvailable,
  selectAuthenticationToken,
  selectCurrentForm,
  selectIsPointsCardSupported,
  selectPointsCardType,
  selectPopup,
  selectPresValue,
} from "redux/helpers/helpers.selector";
import { updateFormErrors, updateIsTributeGift } from "redux/form/form.reducer";
import { updateCurrentForm, updatePopup } from "redux/helpers/helpers.reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  scrollToTheError,
  scrollToTheTop,
  validate,
} from "utils/helper-functions";
import {
  ValidationErrors,
  DonationType as TDonationType,
  PointsCardVariant,
  FormSourceType,
} from "utils/interfaces";
import { schemaConstructor } from "utils/validation-schemas";
import {
  HorizontalLine,
  CCReportTributeLink,
} from "./donation-type-step.styles";
import {
  NextStepButton,
  BottomButtonsContainer,
  CheckboxContainer,
  FormPartContainer,
} from "global";
import { useTranslation } from "react-i18next";
import {
  selectIsHonourSupported,
  selectIsMemorySupported,
} from "redux/presValue/presValue.selector";
import analyticsAPI from "utils/analyticsAPI";
import { popupMessages } from "utils/variables";
import { ValidationError } from "yup";
import DonationInfoForm from "components/donation-info-form/donation-info-form.component";
import OverlayHeadingNavigation from "components/overlay-heading-navigation/overlay-heading-navigation.component";

const TributeForm = lazy(
  () => import("components/tribute-form/tribute-form.component"),
);

const DonationType: React.FC = () => {
  // GLOBAL STATE
  const currentForm = useAppSelector(selectCurrentForm);
  const isTributeGift = useAppSelector(selectIsTributeGift);
  const isMemorySupported = useAppSelector(selectIsMemorySupported);
  const isHonourSupported = useAppSelector(selectIsHonourSupported);
  const donationInfo = useAppSelector(selectDonationInfo);
  const presValue = useAppSelector(selectPresValue);
  const assetsAvailable = useAppSelector(selectAssetsAvailable);
  const isPointsCardSupported = useAppSelector(selectIsPointsCardSupported);
  const pointsCardType = useAppSelector(selectPointsCardType);
  const loyaltyCardNumber = useAppSelector(selectLoyaltyCardNumber);
  const authenticationToken = useAppSelector(selectAuthenticationToken);
  const source = useAppSelector(selectSource);
  const popup = useAppSelector(selectPopup);
  const dispatch = useAppDispatch();

  // LOCAL STATE
  const { t } = useTranslation();
  const appInsights = useAppInsightsContext();
  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;
  const isMemorialGivingForm = source === FormSourceType.MEMORIAL_GIVING_FORM;
  const isDevelopmentForm = source === FormSourceType.DEVELOPMENT_TEAM;
  const isCCForm = presValue === "CC";
  const isFDForm =
    source === FormSourceType.FUNDRAISING_DIRECT &&
    Boolean(authenticationToken);
  const isGPForm = source === FormSourceType.GP_TEAM;
  const isCCFDForm = isFDForm || isCCForm;

  const handleNextForm = async (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent,
  ) => {
    e.preventDefault();
    analyticsAPI.handleNextStep(appInsights, { currentForm });
    const schema = schemaConstructor("donationType", {
      loyaltyCardNumber,
      isInternalForm: isCCFDForm || isDevelopmentForm,
      isGPForm,
      isDevelopmentForm,
      isAuthenticatedForm: Boolean(authenticationToken),
    });

    try {
      await validate(donationInfo, schema);

      dispatch(updateCurrentForm(currentForm + 1));
      dispatch(updateFormErrors({}));

      // Scroll to the top
      scrollToTheTop();
    } catch (error: unknown) {
      const errors: ValidationErrors = {};

      (error as ValidationError).inner.forEach((err) => {
        if (err.path) {
          errors[err.path] = err.message;
        }
      });

      scrollToTheError(Object.keys(errors)[0]);

      // Display popup
      dispatch(updateFormErrors(errors));
      if (!isOverlayForm)
        dispatch(
          updatePopup({
            ...popup,
            message: popupMessages.errorsFixed,
            isError: true,
            isActive: true,
            isLoading: false,
          }),
        );
    }
  };

  const renderPointsCard = useCallback(() => {
    if (!pointsCardType) {
      return;
    }

    const [cardBrand, ...cardTypes] = pointsCardType.split("|");
    const mappedCardTypes = cardTypes?.map((type) => {
      if (type === "PA") return "monthly";
      return "one-time";
    });

    const pointsCardVariants = {
      Aeroplan: (!donationInfo.donationType ||
        mappedCardTypes.includes(
          donationInfo.donationType as TDonationType,
        )) && <Aeroplan />,
    };

    return pointsCardVariants[cardBrand as PointsCardVariant];
  }, [donationInfo.donationType, pointsCardType]);

  return (
    <FormPartContainer
      currentForm={currentForm}
      formID={0}
      isOverlayForm={isOverlayForm}
    >
      {isOverlayForm && (
        <OverlayHeadingNavigation headingText="Make a donation" />
      )}
      {/* CALL CENTER TRIBUTE REPORT BUTTON */}
      {presValue === "CC" && (
        <div style={{ display: "flex", gap: "24px" }}>
          <CCReportTributeLink
            href={process.env.REACT_APP_REPORT_TRIBUTE_LINK}
            target="_blank"
            rel="noreferrer"
          >
            {t("Tribute Report")}
          </CCReportTributeLink>

          <CCReportTributeLink
            href={process.env.REACT_APP_FUNERAL_HOME_LINK}
            target="_blank"
            rel="noreferrer"
          >
            {t("Funeral Home Materials")}
          </CCReportTributeLink>
        </div>
      )}

      <DonationInfoForm renderPointsCard={renderPointsCard} />

      {isPointsCardSupported && renderPointsCard()}

      {donationInfo.donationType !== "monthly" &&
        !isOverlayForm &&
        isMemorySupported &&
        isHonourSupported &&
        assetsAvailable && (
          <>
            {!isMemorialGivingForm && (
              <>
                <HorizontalLine color="#D9D9D9" />
                <CheckboxContainer data-cy="inHonour">
                  <input
                    type="checkbox"
                    id="inHonour"
                    checked={isTributeGift}
                    onChange={() =>
                      dispatch(updateIsTributeGift(!isTributeGift))
                    }
                  />
                  <label htmlFor="inHonour">
                    {t("In Honour Gift Checkbox")}
                  </label>
                </CheckboxContainer>
              </>
            )}

            <Suspense fallback={<p>Loading...</p>}>
              {isTributeGift && (
                <TributeForm
                  handleNextForm={handleNextForm}
                  variant={
                    isMemorialGivingForm ||
                    (!isDevelopmentForm && !isGPForm && !isCCFDForm)
                      ? "memorial"
                      : "regular"
                  }
                />
              )}
            </Suspense>
          </>
        )}

      <BottomButtonsContainer isOverlayForm={isOverlayForm}>
        <NextStepButton isOverlayForm={isOverlayForm} onClick={handleNextForm}>
          {t(isOverlayForm ? "Continue" : "Next Step")}
        </NextStepButton>
      </BottomButtonsContainer>
    </FormPartContainer>
  );
};

export default DonationType;
