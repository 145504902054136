import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  BottomButtonsContainer,
  CheckboxContainer,
  NextStepButton,
} from "global";
import { resetFormReducer, updateFormErrors } from "redux/form/form.reducer";
import {
  selectAppealCode,
  selectCallCentreNumber,
  selectCardActive,
  selectCoverTransactionFee,
  selectDebitAccountDetails,
  selectDonationAmount,
  selectDonationInfo,
  selectDonationType,
  selectDonorInfo,
  selectFormLanguage,
  selectHonourCardType,
  selectHonourType,
  selectIsCompanyGift,
  selectIsDuplicateEcard,
  selectIsPrintReceipt,
  selectLoyaltyCardNumber,
  selectPaymentMethod,
  selectSource,
} from "redux/form/form.selector";
import {
  updateCurrentForm,
  updateHasAgreedToTerms,
  updateIsFormDisabled,
  updatePopup,
} from "redux/helpers/helpers.reducer";
import {
  selectAuthenticationToken,
  selectContextualBoxHTML,
  selectHasAgreedToTerms,
  selectIsCheckoutCallError,
  selectOpportunityId,
  selectPopup,
  selectPresValue,
  selectSession,
} from "redux/helpers/helpers.selector";
import { selectPresId } from "redux/presValue/presValue.selector";
import analyticsAPI from "utils/analyticsAPI";
import {
  getMonthlyWithdrawalDatePostfix,
  prepareTributeNameValuesForCheckout,
  scrollToTheTop,
  sendCartAbandon,
  useIsDesktop,
  validate,
} from "utils/helper-functions";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  CheckoutBody,
  FormSourceType,
  ValidationErrors,
} from "utils/interfaces";
import serviceCallsAPI from "utils/serviceCallsAPI";
import { schemaConstructor } from "utils/validation-schemas";
import {
  overlayStepNumberToNameMap,
  popupMessages,
  primaryColor,
  theme,
} from "utils/variables";
import BackButton from "components/back-button/back-button.component";
import DebitForm from "components/debit-form/debit-form.component";
import StripePaymentElement from "components/payment-element/payment-element.component";
import { CallCenterRecordingText } from "components/payment-step/payment-step.styles";
import { ValidationError } from "yup";
import {
  ContextualBlock,
  OverlayPaymentSummaryContainer,
  TotalAmountContainer,
} from "./checkout-form.styles";
import datalayerAPI from "utils/datalayerAPI";

interface Props {
  clientSecret?: string;
  stripePromise?: Stripe | PromiseLike<Stripe | null> | null;
  paymentID: string;
  dateMonthlyWithdrawal: number;
  monthlyWithdrawalMilliseconds?: Date;
}

const CheckoutForm: React.FC<Props> = ({
  clientSecret,
  stripePromise,
  paymentID,
  dateMonthlyWithdrawal,
  monthlyWithdrawalMilliseconds,
}) => {
  // URL Parameters
  const [searchParams] = useSearchParams();
  const formTypeURL = searchParams.get("type") || searchParams.get("s_fT");
  const lookupId = searchParams.get("lookupId");
  const cid = searchParams.get("cid");

  // LOCAL STATE
  const [stripe, setStripe] = useState<Stripe | null>();
  const [elements, setElements] = useState<StripeElements | null>();
  const [isLoading, setIsLoading] = useState(false);

  // GLOBAL STATE
  const { firstName, lastName, city, addressLine1, postal } =
    useAppSelector(selectDonorInfo);
  const presValue = useAppSelector(selectPresValue);
  const paymentMethod = useAppSelector(selectPaymentMethod);
  const formLanguage = useAppSelector(selectFormLanguage);
  const donationType = useAppSelector(selectDonationType);
  const isCoverFees = useAppSelector(selectCoverTransactionFee);
  const honourType = useAppSelector(selectHonourType);
  const honourCardType = useAppSelector(selectHonourCardType);
  const isCompanyGift = useAppSelector(selectIsCompanyGift);
  const donationAmount = useAppSelector(selectDonationAmount);
  const ecardId = useAppSelector(selectCardActive);
  const opportunityId = useAppSelector(selectOpportunityId);
  const donorInfo = useAppSelector(selectDonorInfo);
  const presid = useAppSelector(selectPresId);
  const appealCode = useAppSelector(selectAppealCode);
  const language = useAppSelector(selectFormLanguage);
  const coverFees = useAppSelector(selectCoverTransactionFee);
  const sendTributeCopy = useAppSelector(selectIsDuplicateEcard);
  const donationInfo = useAppSelector(selectDonationInfo);
  const source = useAppSelector(selectSource);
  const isPrintReceipt = useAppSelector(selectIsPrintReceipt);
  const callCentreNumber = useAppSelector(selectCallCentreNumber);
  const loyaltyCardNumber = useAppSelector(selectLoyaltyCardNumber);
  const debitAccountDetails = useAppSelector(selectDebitAccountDetails);
  const hasAgreedToTerms = useAppSelector(selectHasAgreedToTerms);
  const session = useAppSelector(selectSession);
  const popup = useAppSelector(selectPopup);
  const isCheckoutCallError = useAppSelector(selectIsCheckoutCallError);
  const authenticationToken = useAppSelector(selectAuthenticationToken);
  const contextualBoxHTML = useAppSelector(selectContextualBoxHTML);
  const isDesktop = useIsDesktop();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    honoureeName: tributeName,
    honoureeFirstName,
    honoureeLastName,
    recipientFirstName,
    recipientLastName,
    cardRecipientFullName,
    cardRecipientTitle,
    emailSubject: tributeSubject,
    isTributeGift,
    personalMessage: tributeNote,
    recipientEmail: tributeEmail,
    cardActive: tributeEcardId,
  } = donationInfo;

  // ADDITIONAL SERVICES
  const appInsights = useAppInsightsContext();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;
  const isDevelopmentForm = source === FormSourceType.DEVELOPMENT_TEAM;
  const isDevelopmentDonorForm =
    source === FormSourceType.DEVELOPMENT_TEAM_DONOR;
  const isGPForm = source === FormSourceType.GP_TEAM;
  const isMonthlyGift = donationType === "monthly";
  const legalTermsLabel = t(
    `Monthly terms ${paymentMethod === "card" ? "card" : "debit"}`,
    {
      actionText: t(
        isOverlayForm ? "By submitting this form" : "By checking this option",
      ),
    },
  );

  const options = {
    clientSecret,
    appearance: {
      theme,
      variables: {
        colorPrimary: primaryColor,
        borderRadius: isOverlayForm ? "6px" : "0",
        fontFamily: `${isOverlayForm ? "Neue" : "Arial"}, sans-serif`,
        fontSizeBase: isOverlayForm ? "15px" : "22px",
      },
    },
  };

  const stopAndShowPopup = (message: string) => {
    dispatch(
      updatePopup({
        ...popup,
        isActive: true,
        isError: true,
        message,
        isLoading: false,
      }),
    );
    setIsLoading(false);
    dispatch(updateIsFormDisabled(false));
  };

  const updatePayment = async (
    requestBody: CheckoutBody,
    paymentID: string,
  ) => {
    const checkoutResp = await serviceCallsAPI.callCheckoutUpdate(
      nanoid(),
      requestBody,
      paymentID,
    );

    // istanbul ignore next
    if (checkoutResp.status !== 200) {
      throw new Error();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!executeRecaptcha) return;

    setIsLoading(true);
    dispatch(
      updatePopup({
        ...popup,
        isError: false,
        isActive: true,
        message: popupMessages.processRequest,
        isLoading: true,
      }),
    );
    dispatch(updateIsFormDisabled(true));

    if (isMonthlyGift && !hasAgreedToTerms && !isOverlayForm) {
      stopAndShowPopup(popupMessages.agreeToTerms);
      return;
    }

    if (!authenticationToken) {
      try {
        const token = await executeRecaptcha("PAYMENT");
        const response = await serviceCallsAPI.callRecaptcha({
          token,
          action: "PAYMENT",
        });
        const assessment = await response.json();

        // Analytics call with recaptcha score
        analyticsAPI.handleRecaptcha(
          appInsights,
          token,
          !assessment ? 0 : assessment.score,
        );

        // istanbul ignore next
        if (!assessment || assessment.score < 0.5) {
          stopAndShowPopup(popupMessages.somethingWentWrong);
          return;
        }
        // istanbul ignore next
      } catch (err) {
        stopAndShowPopup(popupMessages.somethingWentWrong);
        return;
      }
    }

    // Create url for thank you page
    const thankyouPageUrl = `/thank-you?${
      formTypeURL ? "type=" + formTypeURL + "&" : ""
    }${presValue ? "pres=" + presValue + "&" : ""}${
      source ? "source=" + source + "&" : ""
    }${
      lookupId ? "lookupId=" + lookupId + "&" : ""
    }${cid ? "cid=" + cid + "&" : ""}locale=${formLanguage}&name=${encodeURIComponent(
      firstName,
    )}&confNumber=${opportunityId}${
      isMonthlyGift ? "&giftType=monthly" : ""
    }&province=${donorInfo.provinceState}`;

    // istanbul ignore if
    if (paymentMethod === "card") {
      if (!stripe || !elements) return;

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: `${firstName} ${lastName}`,
              address: {
                line1: addressLine1,
                city,
                country: "CA",
                postal_code: postal,
              },
            },
          },
          return_url: window.location.origin + thankyouPageUrl,
        },
        redirect: "if_required",
      });

      if (paymentIntent?.status === "succeeded") {
        analyticsAPI.handleSuccessfulPayment(appInsights, {
          name: "Successful Credit Card Payment",
          donationAmount,
          donationType,
          ecardId,
          paymentId: paymentIntent.id,
          isCoverFees,
          honourType,
          honourCardType,
          isCompanyGift,
          isOverlayForm,
        });

        sendCartAbandon(
          donorInfo,
          isCompanyGift,
          session,
          donationAmount,
          formLanguage,
          loyaltyCardNumber,
          donationType,
          source,
          true,
        );

        dispatch(
          updatePopup({
            ...popup,
            isActive: false,
          }),
        );
        scrollToTheTop();
        dispatch(updateIsFormDisabled(false));

        // Clear the state in case the user decides to click browser back arrow
        dispatch(resetFormReducer());
        dispatch(updateCurrentForm(0));

        navigate(thankyouPageUrl);
        return;
      }

      // This point will only be reached if there is an immediate error when confirming the payment.
      if (error?.type === "card_error" || error?.type === "validation_error") {
        if (error.decline_code === "fraudulent") {
          alert("Fraudelent card");
        } else {
          dispatch(
            updatePopup({
              ...popup,
              isActive: true,
              message: popupMessages.incorrectCard,
              isError: true,
              isLoading: false,
            }),
          );
        }
      } else {
        dispatch(
          updatePopup({
            ...popup,
            isActive: true,
            message: popupMessages.somethingWentWrong,
            isError: true,
            isLoading: false,
          }),
        );
      }
      if (isOverlayForm)
        datalayerAPI.handleOverlayValidationErrors(
          4,
          overlayStepNumberToNameMap[3],
        );
    } else {
      const schema = schemaConstructor("debit");

      // Validate the fields
      try {
        await validate(debitAccountDetails, schema);
        dispatch(updateFormErrors({}));

        // Prepare request body
        const tributeNames = prepareTributeNameValuesForCheckout(
          isTributeGift,
          {
            tributeName,
            honoureeFirstName,
            honoureeLastName,
            cardRecipientFullName,
            recipientFirstName,
            recipientLastName,
          },
        );
        const requestBody: CheckoutBody = {
          amount: donationAmount,
          ...donorInfo,
          presid,
          appealCode,
          giftType: donationType === "one-time" ? "OTG" : "PA",
          donationType: !isCompanyGift ? "Individual" : "Company",
          language,
          tributeSubject: (isTributeGift && tributeSubject) || undefined,
          tributeRecipientTitle:
            (isTributeGift && cardRecipientTitle) || undefined,
          ...tributeNames,
          tributeNote: (isTributeGift && tributeNote) || undefined,
          tributeEmail: (isTributeGift && tributeEmail) || undefined,
          tributeAddress:
            (isTributeGift &&
              honourCardType === "mail" &&
              `${donationInfo.honoureeAddressLine}, ${donationInfo.honoureeCity}, ${donationInfo.honoureeProvinceState}, ${donationInfo.honoureeCountry}, ${donationInfo.honoureePostal}`) ||
            undefined,
          tributeType: (isTributeGift && honourType) || undefined,
          tributeEcardId: tributeEcardId || undefined,
          coverFees,
          isPrintReceipt,
          doNotReceipt: isDevelopmentForm || isDevelopmentDonorForm,
          emailTributeEcard:
            isTributeGift && honourCardType === "ecard" ? true : false,
          mailTributeEcard:
            isTributeGift && honourCardType === "mail" ? true : false,
          sendTributeCopy,
          callCentreNumber: callCentreNumber.trim() || undefined,
          loyaltyCardNumber: loyaltyCardNumber || undefined,
          paymentMethod,
          source,
          institutionNumber:
            paymentMethod === "direct_debit" &&
            debitAccountDetails.institutionNumber
              ? debitAccountDetails.institutionNumber
              : undefined,
          transitNumber:
            paymentMethod === "direct_debit" &&
            debitAccountDetails.transitNumber
              ? debitAccountDetails.transitNumber
              : undefined,
          accountNumber:
            paymentMethod === "direct_debit" &&
            debitAccountDetails.accountNumber
              ? debitAccountDetails.accountNumber
              : undefined,
          sessionid: session.sessionID,
          utcOffset: donationInfo.utcOffset,
        };

        // Make a update call to the service
        try {
          updatePayment(requestBody, paymentID);
        } catch (error: unknown) {
          dispatch(
            updatePopup({
              ...popup,
              isError: true,
              isActive: true,
              message: popupMessages.somethingWentWrong,
              isLoading: false,
            }),
          );
          return;
        }

        dispatch(
          updatePopup({
            ...popup,
            isActive: false,
          }),
        );
        scrollToTheTop();
        dispatch(updateIsFormDisabled(false));

        // Clear the state in case the user decides to click browser back arrow
        dispatch(resetFormReducer());
        dispatch(updateCurrentForm(0));

        sendCartAbandon(
          donorInfo,
          isCompanyGift,
          session,
          donationAmount,
          formLanguage,
          loyaltyCardNumber,
          donationType,
          source,
          true,
        );

        // Navigate to thank you page
        navigate(thankyouPageUrl);
      } catch (error: unknown) {
        const errors: ValidationErrors = {};

        (error as ValidationError).inner.forEach((err) => {
          if (err.path) {
            errors[err.path] = err.message;
          }
        });

        // Display popup
        dispatch(updateFormErrors(errors));
        dispatch(
          updatePopup({
            ...popup,
            message: popupMessages.errorsFixed,
            isError: true,
            isActive: true,
            isLoading: false,
          }),
        );
      }
    }

    setIsLoading(false);
    dispatch(updateIsFormDisabled(false));
  };

  return (
    <div
      onKeyDown={(e: React.KeyboardEvent) => {
        if (isDesktop && e.key === "Enter") handleSubmit(e);
      }}
    >
      {/* Call Center Text */}
      {presValue === "CC" && (
        <CallCenterRecordingText data-cy="callCentreRecordingText">
          {t("Recording off")}
        </CallCenterRecordingText>
      )}
      {/* LANGUAGE SWITCH TEXT */}
      {(isDevelopmentForm || isGPForm) && (
        <CallCenterRecordingText>
          {t("Donor's language French")}
        </CallCenterRecordingText>
      )}

      {/* Stripe Element or Debit Form */}
      {paymentMethod === "card" ? (
        clientSecret &&
        clientSecret !== "direct_debit" &&
        stripePromise && (
          <Elements key={clientSecret} options={options} stripe={stripePromise}>
            <StripePaymentElement
              updateStripe={(stripe: Stripe | null) => setStripe(stripe)}
              updateStripeElements={(elements: StripeElements | null) =>
                setElements(elements)
              }
            />
          </Elements>
        )
      ) : (
        <DebitForm />
      )}

      {isOverlayForm ? (
        <OverlayPaymentSummaryContainer>
          <TotalAmountContainer>
            <p>{t("Total")}</p>

            <p className="bold">
              ${donationAmount} CAD {isMonthlyGift && <>/ {t("month")}</>}
            </p>
          </TotalAmountContainer>

          {isMonthlyGift && (
            <>
              <p style={{ fontSize: 13 }}>
                {t("Your monthly gift process", {
                  date: dateMonthlyWithdrawal,
                  afterNumber: getMonthlyWithdrawalDatePostfix(
                    dateMonthlyWithdrawal,
                  ),
                  fullDate: monthlyWithdrawalMilliseconds
                    ?.toISOString()
                    .split("T")[0],
                })}
              </p>
              <p style={{ fontSize: 13 }}>{legalTermsLabel}</p>
            </>
          )}
        </OverlayPaymentSummaryContainer>
      ) : isMonthlyGift ? (
        <CheckboxContainer>
          <input
            type="checkbox"
            id="terms"
            checked={hasAgreedToTerms}
            onChange={() => dispatch(updateHasAgreedToTerms(!hasAgreedToTerms))}
          />
          <label htmlFor="terms">{legalTermsLabel}</label>
        </CheckboxContainer>
      ) : (
        <p>{t("One time terms")}</p>
      )}

      {/* Call Center Text */}
      {presValue === "CC" && (
        <CallCenterRecordingText>{t("Recording on")}</CallCenterRecordingText>
      )}

      {/* Bottom Buttons */}
      <BottomButtonsContainer isOverlayForm={isOverlayForm}>
        {!isOverlayForm && <BackButton />}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: isOverlayForm ? "100%" : "auto",
          }}
        >
          {isOverlayForm && contextualBoxHTML[formLanguage] && (
            <ContextualBlock
              dangerouslySetInnerHTML={{
                __html: contextualBoxHTML[formLanguage],
              }}
            />
          )}

          <NextStepButton
            disabled={
              isLoading ||
              (paymentMethod === "card" && (!stripe || !elements)) ||
              isCheckoutCallError
            }
            isOverlayForm={isOverlayForm}
            onClick={handleSubmit}
          >
            {isLoading
              ? t("Loading")
              : t(isOverlayForm ? "Donate" : "Complete Donation")}
          </NextStepButton>
        </div>
      </BottomButtonsContainer>
    </div>
  );
};

export default CheckoutForm;
