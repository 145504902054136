import ErrorMessage from "components/error-message/error-message.component";
import { InputContainer, RedStar } from "global";
import { FC, InputHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { StyledInput } from "./input.styles";
import { useAppSelector } from "utils/hooks";
import { selectSource } from "redux/form/form.selector";
import { FormSourceType } from "utils/interfaces";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  isError?: string;
  label: string;
  isRequired?: boolean;
  belowLabelContent?: React.ReactNode;
  belowInputContent?: React.ReactNode;
  withoutColon?: boolean;
}

const Input: FC<InputProps> = ({
  isError,
  label,
  isRequired,
  id,
  belowLabelContent,
  belowInputContent,
  withoutColon,
  ...props
}) => {
  // GLOBAL STATE
  const source = useAppSelector(selectSource);
  const { t } = useTranslation();

  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;

  return (
    <InputContainer isOverlayForm={isOverlayForm}>
      <label htmlFor={id}>
        {isRequired && <RedStar>*</RedStar>} {t(label)}
        {!withoutColon && ":"}
      </label>
      {belowLabelContent}
      <StyledInput
        id={id}
        isError={isError}
        maxLength={50}
        isOverlayForm={isOverlayForm}
        {...props}
      />
      {belowInputContent}
      {isError && <ErrorMessage errorMessage={isError} />}
    </InputContainer>
  );
};

export default Input;
