import { FC } from "react";
import { ErrorMessageStyled } from "./error-message.styles";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "utils/hooks";
import { selectSource } from "redux/form/form.selector";
import { FormSourceType } from "utils/interfaces";

interface Props {
  errorMessage: string;
}

const ErrorMessage: FC<Props> = ({ errorMessage, ...props }) => {
  // GLOBAL STATE
  const source = useAppSelector(selectSource);
  const { t } = useTranslation();

  // LOCAL STATE
  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;

  return (
    <ErrorMessageStyled isOverlayForm={isOverlayForm} {...props}>
      <span>!</span> {t(errorMessage)}
    </ErrorMessageStyled>
  );
};

export default ErrorMessage;
