import React, { useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useAppSelector } from "utils/hooks";
import { selectFormLanguage, selectSource } from "redux/form/form.selector";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { selectIsCheckoutCallError } from "redux/helpers/helpers.selector";
import { FormSourceType } from "utils/interfaces";

interface Props {
  updateStripe: (stripe: Stripe | null) => void;
  updateStripeElements: (elements: StripeElements | null) => void;
}

// istanbul ignore next
const StripePaymentElement: React.FC<Props> = ({
  updateStripe,
  updateStripeElements,
}) => {
  // GLOBAL STATE
  const formLanguage = useAppSelector(selectFormLanguage);
  const isCheckoutCallError = useAppSelector(selectIsCheckoutCallError);
  const source = useAppSelector(selectSource);
  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;

  // Stripe config
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    updateStripe(stripe);
    updateStripeElements(elements);
  }, [stripe, elements]); // eslint-disable-line

  useEffect(() => {
    if (elements) {
      elements.update({ locale: formLanguage });
    }
  }, [formLanguage]); // eslint-disable-line

  return (
    <div style={{ marginBottom: isOverlayForm ? "32px" : "20px" }}>
      <PaymentElement
        options={{
          readOnly: isCheckoutCallError,
          terms: { card: "never" },
          fields: {
            billingDetails: {
              address: {
                country: "never",
                postalCode: "never",
              },
            },
          },
        }}
        id="payment-element"
      />
    </div>
  );
};

export default StripePaymentElement;
