import { FC } from "react";
import { useIsDesktop } from "utils/helper-functions";
import { RightImage } from "./contextual-image.styles";
import {
  selectContextualImage,
  selectThankYouContextualImage,
} from "redux/presValue/presValue.selector";
import { useAppSelector } from "utils/hooks";
import { selectFormLanguage } from "redux/form/form.selector";
import { useTranslation } from "react-i18next";

interface Props {
  isTYPage?: boolean;
}

export const ContextualImage: FC<Props> = ({ isTYPage }) => {
  const contextualImage = useAppSelector(selectContextualImage);
  const thankYouContextualImage = useAppSelector(selectThankYouContextualImage);
  const formLanguage = useAppSelector(selectFormLanguage);
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  return (
    <RightImage
      src={
        isTYPage && thankYouContextualImage
          ? thankYouContextualImage[formLanguage].contextualImageDT
          : contextualImage[formLanguage][
              isDesktop ? "contextualImageDT" : "contextualImageM"
            ]
      }
      alt={t("Heart and Stroke Researcher")}
    />
  );
};

export default ContextualImage;
