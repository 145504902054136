import { FC } from "react";
import {
  BannerContainer,
  ConfirmationNumber,
  SeeImpactLink,
  ThankYouText,
  ThankYouTextOverlayContainer,
} from "components/thank-you-content/thank-you-content.styles";
import { useTranslation } from "react-i18next";
import { ThankYoutContentProps } from "components/thank-you-content/thank-you-content.types";
import { selectFormLanguage } from "redux/form/form.selector";
import { useAppSelector } from "utils/hooks";

const ThankYouContentOverlay: FC<ThankYoutContentProps> = ({
  donorName,
  confNumber,
  bannerLink,
  bannerImageLink,
  handleBannerClick,
}) => {
  const formLanguage = useAppSelector(selectFormLanguage);
  const { t } = useTranslation();

  return (
    <ThankYouTextOverlayContainer>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <ThankYouText isOverlayForm>
          {t("thank you for donation")}, {donorName}
        </ThankYouText>

        <ConfirmationNumber isOverlayForm>
          {t("Confirmation number")}: {confNumber}
        </ConfirmationNumber>
      </div>

      <p style={{ fontSize: "20px" }}>
        {t("Together, we will beat heart disease and stroke")}
      </p>

      <BannerContainer isOverlayForm>
        <a
          href={bannerLink}
          target="_blank"
          rel="noreferrer"
          onClick={handleBannerClick}
        >
          <img src={bannerImageLink} alt="Ride For Heart Banner" />
        </a>
      </BannerContainer>

      <SeeImpactLink
        isOverlayForm
        href={
          formLanguage === "en"
            ? process.env.REACT_APP_SEE_IMPACT_URL_EN
            : process.env.REACT_APP_SEE_IMPACT_URL_FR
        }
        target="_blank"
      >
        {t("See the impact")}
      </SeeImpactLink>
    </ThankYouTextOverlayContainer>
  );
};

export default ThankYouContentOverlay;
