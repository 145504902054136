import { FC } from "react";
import {
  BannerContainer,
  ConfirmationNumber,
  EmailLink,
  SeeImpactLink,
  ThankYouButtonsContainer,
  ThankYouParagraph,
  ThankYouText,
  ThankYouTextContainer,
  TogetherText,
} from "components/thank-you-content/thank-you-content.styles";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "utils/hooks";
import { selectFormLanguage } from "redux/form/form.selector";
import { ThankYoutContentProps } from "components/thank-you-content/thank-you-content.types";

const ThankYouContentRegular: FC<ThankYoutContentProps> = ({
  donorName,
  confNumber,
  giftType,
  newDonationUrl,
  bannerImageLink,
  bannerLink,
  isDevelopmentForm,
  isMemorialGivingForm,
  handleBannerClick,
}) => {
  // GLOBAL STATE
  const formLanguage = useAppSelector(selectFormLanguage);
  const { t } = useTranslation();

  return (
    <ThankYouTextContainer>
      <ThankYouText>
        {donorName}, {t("thank you for gift")}!
      </ThankYouText>
      <ConfirmationNumber>
        {t("Your confirmation number")} {confNumber}
      </ConfirmationNumber>
      {isMemorialGivingForm ? (
        <TogetherText>{t("Thank you for honouring loved one")}</TogetherText>
      ) : (
        <TogetherText>
          {t("Together, we'll")} <span>{t("beat")}</span>{" "}
          {t("heart disease and stroke")}
        </TogetherText>
      )}
      <ThankYouParagraph>
        {t("thank you for supporting breakthrough")}
      </ThankYouParagraph>
      {giftType !== "monthly" && (
        <ThankYouParagraph>
          {t(isDevelopmentForm ? "dev form tax receipt" : "tax receipt")}{" "}
          <EmailLink href="mailto:donorinfo@heartandstroke.ca">
            donorinfo@heartandstroke.ca
          </EmailLink>
          .
        </ThankYouParagraph>
      )}
      <ThankYouButtonsContainer>
        <SeeImpactLink
          href={
            formLanguage === "en"
              ? process.env.REACT_APP_SEE_IMPACT_URL_EN
              : process.env.REACT_APP_SEE_IMPACT_URL_FR
          }
          target="_blank"
        >
          {t("See the impact")}
        </SeeImpactLink>
        <SeeImpactLink href={newDonationUrl}>
          {t("Start a new donation")}
        </SeeImpactLink>
      </ThankYouButtonsContainer>
      <BannerContainer>
        <a
          href={bannerLink}
          target="_blank"
          rel="noreferrer"
          onClick={handleBannerClick}
        >
          <img src={bannerImageLink} alt="Ride For Heart Banner" />
        </a>
      </BannerContainer>
    </ThankYouTextContainer>
  );
};

export default ThankYouContentRegular;
