import { NavigationContainer } from "components/navigation/navigation.styles";
import { FC } from "react";
import {
  AmountButtonSkeletonContainer,
  ButtonSkeletonContainer,
  FormSkeletonContainer,
  HeadingWithButtonsContainer,
  NavButtonSkeletonContainer,
  OffsetFeesSkeletonContainer,
  OtherInputSkeletonContainer,
  TypeButtonsSkeletonContainer,
} from "./form-skeleton.styles";
import { buttonsCollection } from "utils/variables";
import SkeletonBlock from "components/skeleton-block/skeleton-block.component";
import { FormPartContainer } from "global";
import { AmountButtonsContainer } from "components/donation-info-form/donation-info-form.styles";
import { useAppSelector } from "utils/hooks";
import { selectSource } from "redux/form/form.selector";
import { FormSourceType } from "utils/interfaces";

interface Props {
  isContextual: boolean;
}

const FormSkeleton: FC<Props> = ({ isContextual }) => {
  const source = useAppSelector(selectSource);
  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;

  return (
    <>
      <NavigationContainer isContextual={isContextual}>
        {buttonsCollection.map((button, index) => (
          <NavButtonSkeletonContainer key={index}>
            <SkeletonBlock width="62px" height="53px" />
            <SkeletonBlock width="73px" height="15.5px" />
          </NavButtonSkeletonContainer>
        ))}
      </NavigationContainer>

      <FormSkeletonContainer isContextual={isContextual}>
        <FormPartContainer>
          <HeadingWithButtonsContainer>
            <SkeletonBlock width="510px" height="31px" />

            <TypeButtonsSkeletonContainer>
              <SkeletonBlock height="60px" />
              <SkeletonBlock height="60px" />
            </TypeButtonsSkeletonContainer>
          </HeadingWithButtonsContainer>

          <HeadingWithButtonsContainer>
            <SkeletonBlock width="330px" height="31px" />
            <AmountButtonsContainer isOverlayForm={isOverlayForm}>
              {Array.from({ length: 3 }).map((_, index) => (
                <AmountButtonSkeletonContainer key={index}>
                  <SkeletonBlock />
                </AmountButtonSkeletonContainer>
              ))}
              <OtherInputSkeletonContainer>
                <SkeletonBlock />
              </OtherInputSkeletonContainer>
            </AmountButtonsContainer>
          </HeadingWithButtonsContainer>

          <OffsetFeesSkeletonContainer>
            <SkeletonBlock
              width="880px"
              height="21px"
              styles={{ maxWidth: "100%" }}
            />
            <SkeletonBlock width="380px" height="21px" />
          </OffsetFeesSkeletonContainer>

          <SkeletonBlock width="380px" height="21px" />

          <ButtonSkeletonContainer>
            <SkeletonBlock width="158px" height="60px" />
          </ButtonSkeletonContainer>
        </FormPartContainer>
      </FormSkeletonContainer>
    </>
  );
};

export default FormSkeleton;
