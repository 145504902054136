import { FC } from "react";
import { SkeletonItem } from "./skeleton-block.styles";
import { CSSProperties } from "styled-components";

interface Props {
  height?: string;
  width?: string;
  backgroundColor?: string;
  styles?: CSSProperties;
  withAnimation?: boolean;
}

const SkeletonBlock: FC<Props> = ({
  width,
  height,
  backgroundColor,
  styles,
  withAnimation = true,
}) => {
  return (
    <SkeletonItem
      width={width || "100%"}
      height={height || "100%"}
      backgroundColor={backgroundColor || "#f0f0f0"}
      style={styles}
      withAnimation={withAnimation}
    />
  );
};

export default SkeletonBlock;
