import { FC } from "react";

const BackArrow: FC = () => {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.386719 8.00684C0.386719 7.90169 0.406087 7.80485 0.444824 7.71631C0.483561 7.62223 0.541667 7.53646 0.619141 7.45898L7.20166 1.01758C7.35107 0.873698 7.53092 0.801758 7.74121 0.801758C7.88509 0.801758 8.01237 0.834961 8.12305 0.901367C8.23926 0.967773 8.33057 1.05908 8.39697 1.17529C8.46891 1.28597 8.50488 1.41325 8.50488 1.55713C8.50488 1.76188 8.43018 1.94173 8.28076 2.09668L2.23779 8.00684L8.28076 13.917C8.43018 14.0719 8.50488 14.2518 8.50488 14.4565C8.50488 14.6004 8.46891 14.7277 8.39697 14.8384C8.33057 14.9546 8.23926 15.0459 8.12305 15.1123C8.01237 15.1787 7.88509 15.2119 7.74121 15.2119C7.53092 15.2119 7.35107 15.1372 7.20166 14.9878L0.619141 8.55469C0.541667 8.47721 0.483561 8.39421 0.444824 8.30566C0.406087 8.21159 0.386719 8.11198 0.386719 8.00684Z"
        fill="black"
      />
    </svg>
  );
};

export default BackArrow;
