import { FC } from "react";
import { OverlayFormSkeletonContainer } from "./overlay-form-skeleton.styles";
import SkeletonBlock from "components/skeleton-block/skeleton-block.component";

const OverlayFormSkeleton: FC = () => {
  return (
    <OverlayFormSkeletonContainer>
      <SkeletonBlock
        width="180px"
        height="26.5px"
        styles={{ marginBottom: "24px" }}
      />

      <div style={{ display: "flex", marginBottom: "32px" }}>
        <SkeletonBlock height="39px" styles={{ marginRight: "8px" }} />
        <SkeletonBlock height="39px" />
      </div>

      <div style={{ display: "flex", marginBottom: "8px" }}>
        <SkeletonBlock height="39px" styles={{ marginRight: "6px" }} />
        <SkeletonBlock height="39px" styles={{ marginRight: "6px" }} />
        <SkeletonBlock height="39px" />
      </div>
      <SkeletonBlock height="39px" styles={{ marginBottom: "32px" }} />

      <div style={{ marginBottom: "48px" }}>
        <SkeletonBlock
          height="34px"
          width="90%"
          styles={{ marginBottom: "8px" }}
        />

        <div style={{ display: "flex" }}>
          <SkeletonBlock height="39px" styles={{ marginRight: "8px" }} />
          <SkeletonBlock height="39px" />
        </div>
      </div>

      <SkeletonBlock
        height="38px"
        width="183px"
        styles={{ marginLeft: "auto" }}
      />
    </OverlayFormSkeletonContainer>
  );
};

export default OverlayFormSkeleton;
