import React, { Fragment } from "react";
import { updateDebitAccountDetails } from "redux/form/form.reducer";
import {
  selectDebitAccountDetails,
  selectFormErrors,
  selectSource,
} from "redux/form/form.selector";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import Input from "components/input/input.component";
import { TwoColumnsInput } from "global";
import { FormSourceType } from "utils/interfaces";

const DebitForm: React.FC = () => {
  // GLOBAL STATE
  const debitAccountDetails = useAppSelector(selectDebitAccountDetails);
  const formErrors = useAppSelector(selectFormErrors);
  const source = useAppSelector(selectSource);
  const dispatch = useAppDispatch();

  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const regex = /^\d*$/g;
    if (regex.test(value))
      dispatch(
        updateDebitAccountDetails({ ...debitAccountDetails, [name]: value }),
      );
  };

  const InputsContainer = isOverlayForm ? TwoColumnsInput : Fragment;

  return (
    <div style={{ marginBottom: isOverlayForm ? "32px" : "20px" }}>
      <InputsContainer>
        <Input
          label={`Institution number${isOverlayForm ? " overlay" : ""}`}
          id="institutionNumber"
          name="institutionNumber"
          type="text"
          placeholder="000"
          maxLength={3}
          onChange={handleInput}
          value={debitAccountDetails.institutionNumber}
          isError={formErrors.institutionNumber}
          isRequired
        />
        <Input
          label={`Transit number${isOverlayForm ? " overlay" : ""}`}
          id="transitNumber"
          name="transitNumber"
          type="text"
          placeholder="11000"
          maxLength={5}
          onChange={handleInput}
          value={debitAccountDetails.transitNumber}
          isError={formErrors.transitNumber}
          isRequired
        />
      </InputsContainer>

      <Input
        label="Account number"
        id="accountNumber"
        name="accountNumber"
        type="text"
        placeholder="000123456789"
        maxLength={12}
        onChange={handleInput}
        value={debitAccountDetails.accountNumber}
        isError={formErrors.accountNumber}
        isRequired
      />
      <Input
        label="Verify account number"
        id="verifyAccountNumber"
        name="verifyAccountNumber"
        type="text"
        placeholder="000123456789"
        maxLength={12}
        onChange={handleInput}
        value={debitAccountDetails.verifyAccountNumber}
        isError={formErrors.verifyAccountNumber}
        isRequired
      />
    </div>
  );
};

export default DebitForm;
