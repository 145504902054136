import React from "react";
import { SectionHeading } from "global";
import {
  AeroplanContainer,
  AeroplanText,
  AeroplanLogo,
  LoyaltyPointsContainer,
  AeroplanInput,
  AeroplanLabel,
} from "./aeroplan.styles";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { updateLoyaltyCardNumber } from "redux/form/form.reducer";
import {
  selectFormErrors,
  selectLoyaltyCardNumber,
} from "redux/form/form.selector";
import ErrorMessage from "components/error-message/error-message.component";

const Aeroplan: React.FC = () => {
  const formErrors = useAppSelector(selectFormErrors);
  const loyaltyCardNumber = useAppSelector(selectLoyaltyCardNumber);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleAeroplanInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value.length > 9) return;

    // Testing just to be a number
    const regex = /^\d*$/g;
    if (regex.test(value))
      dispatch(updateLoyaltyCardNumber(e.currentTarget.value));
  };

  return (
    <AeroplanContainer aria-live="polite">
      <SectionHeading>{t("Are you aeroplan")}</SectionHeading>
      <AeroplanText>{t("Get points")}</AeroplanText>

      <LoyaltyPointsContainer>
        <AeroplanLogo
          src={process.env.REACT_APP_AEROPLAN_LOGO}
          alt="Aeroplan Logo"
        />
        <AeroplanText>627421</AeroplanText>
        <AeroplanInput
          onChange={handleAeroplanInput}
          type="text"
          id="loyaltyPoints"
          value={loyaltyCardNumber}
          isError={formErrors.loyaltyCardNumber}
        />
        <AeroplanLabel htmlFor="loyaltyPoints">
          {t("9-digit number")}
        </AeroplanLabel>
      </LoyaltyPointsContainer>

      {formErrors.loyaltyCardNumber && (
        <ErrorMessage
          data-cy="loyaltyPointsError"
          errorMessage={formErrors.loyaltyCardNumber}
        />
      )}
    </AeroplanContainer>
  );
};

export default Aeroplan;
