import { FC } from "react";
import {
  overlayAddressInfoSchema,
  overlayDonorInfoSchema,
  schemaConstructor,
} from "./validation-schemas";

export interface FormErrors {
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  city?: string;
  provinceState?: string;
  postal?: string;
  country?: string;
  email?: string;
  companyName?: string;
  donationType?: string;
  donationAmount?: string;
  honourType?: string;
  honoureeName?: string;
  honoureeFirstName?: string;
  honoureeLastName?: string;
  recipientFirstName?: string;
  recipientLastName?: string;
  recipientEmail?: string;
  emailSubject?: string;
  personalMessage?: string;
  cardActive?: string;
  honoureeAddressLine?: string;
  honoureeCountry?: string;
  honoureeCity?: string;
  honoureePostal?: string;
  honoureeProvinceState?: string;
  cardRecipientFullName?: string;
  loyaltyCardNumber?: string;
  phoneNumber?: string;
  callCentreNumber?: string;
  institutionNumber?: string;
  transitNumber?: string;
  accountNumber?: string;
  verifyAccountNumber?: string;
}

export interface ValidationErrors {
  [key: string]: string;
}

export interface DonorInfoValues {
  title: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  city: string;
  provinceState: string;
  postal: string;
  country: string;
  addressLine2: string;
  phoneNumber: string;
  email: string;
  companyName: string;
}

export interface DonorInfoValidation extends DonorInfoValues {
  isCompanyGift: boolean;
  callCentreNumber: string;
}

export interface OverlayDonorInfoValidation {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface OverlayAddressInfoValidation {
  addressLine1: string;
  city: string;
  country: string;
  postal: string;
  provinceState: string;
}

export interface DonationInfoValues {
  donationType?: DonationType;
  donationAmount: number;
  honourType: string;
  honoureeName: string;
  honoureeFirstName: string;
  honoureeLastName: string;
  recipientFirstName: string;
  recipientLastName: string;
  honourCardType: string;
  isCompanyGift: boolean;
  isTributeGift: boolean;
  formLanguage: "en" | "fr";
  recipientEmail: string;
  emailSubject: string;
  personalMessage: string;
  isDuplicateEcard: boolean;
  cardActive: number;
  coverTransactionFee: boolean;
  paymentMethod: "card" | "direct_debit";
  cardRecipientTitle: string;
  cardRecipientFullName: string;
  honoureeAddressLine: string;
  honoureeCity: string;
  honoureeProvinceState: string;
  honoureeCountry: string;
  honoureePostal: string;
  source: string;
  cardsCollection: CardsCollection;
  loyaltyCardNumber: string;
  appealCode?: string;
  isPrintReceipt: boolean;
  callCentreNumber: string;
  giftNotes: string;
  utcOffset?: number;
}

export interface DonationTypeValues {
  donationType?: string;
  donationAmount?: number;
  honourType: string;
  honoureeName: string;
  honoureeFirstName: string;
  honoureeLastName: string;
  recipientFirstName: string;
  recipientLastName: string;
  recipientEmail: string;
  emailSubject: string;
  cardActive: number;
  cardRecipientFullName: string;
  honoureeAddressLine: string;
  honoureeCity: string;
  honoureeProvinceState: string;
  honoureeCountry: string;
  honoureePostal: string;
  isTributeGift: boolean;
  honourCardType: string;
  loyaltyCardNumber: string;
}

export interface FormState {
  donationInfo: DonationInfoValues;
  donorInfo: DonorInfoValues;
  debitAccountDetails: DebitInfoValues;
  formErrors: FormErrors;
}

export interface Popup {
  isActive: boolean;
  message: string;
  isError: boolean;
  isLoading: boolean;
  isSession: boolean;
}

export interface Ecard {
  id: number;
  name: string;
  publishedURL: string;
}

export interface CardsCollection {
  "in-honour": {
    en: Ecard[];
    fr: Ecard[];
  };
  "in-memory": { en: Ecard[]; fr: Ecard[] };
}

export interface Helpers {
  currentForm: number;
  formType: string;
  presValue: string;
  popup: Popup;
  isFormDisabled: boolean;
  isCoverFeeSupported: boolean;
  isPointsCardSupported: boolean;
  pointsCardType: string;
  contextualBoxHTML: { en: string; fr: string };
  thankYouContextualBoxHTML: { en: string; fr: string };
  informationalHTML: { en: string; fr: string };
  footerHTML: { en: string; fr: string };
  monthlySuggestionText: { en: string; fr: string };
  researcherName: string;
  opportunityId: number;
  assetsAvailable: boolean;
  hasAgreedToTerms: boolean;
  isPageError: boolean;
  viewportHeight: number;
  session: Session;
  isCheckoutCallError: boolean;
  authenticationToken?: string;
}

export interface CheckoutBody {
  amount: number;
  title: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  city: string;
  provinceState: string;
  postal: string;
  country: string;
  addressLine2: string;
  phoneNumber: string;
  email: string;
  companyName: string;
  presid: number;
  appealCode?: string;
  giftType: string;
  donationType: string;
  language: "en" | "fr";
  tributeSubject?: string;
  tributeRecipientTitle?: string;
  tributeRecipientFullname?: string;
  tributeName?: string;
  tributeNote?: string;
  tributeEmail?: string;
  tributeAddress?: string;
  tributeType?: string;
  tributeEcardId?: number;
  coverFees: boolean;
  isPrintReceipt: boolean;
  doNotReceipt: boolean;
  emailTributeEcard: boolean;
  mailTributeEcard: boolean;
  sendTributeCopy: boolean;
  callCentreNumber?: string;
  loyaltyCardNumber?: string;
  paymentMethod: "card" | "direct_debit";
  source?: string;
  institutionNumber?: string;
  transitNumber?: string;
  accountNumber?: string;
  sessionid?: string;
  utcOffset?: number;
}

export interface AskAmounts {
  "one-time": number[];
  monthly: number[];
}

export interface PresValueState {
  contextualImage: {
    en: ContextualImage;
    fr: ContextualImage;
  };
  thankYouContextualImage?: {
    en: ContextualImage;
    fr: ContextualImage;
  };
  thankYouBanner?: ThankYouBanner;
  isHonourSupported: boolean;
  isMemorySupported: boolean;
  isOneTimeSupported: boolean;
  isMonthlySupported: boolean;
  askAmounts: AskAmounts;
  presid: number;
}

export interface DebitInfoValues {
  institutionNumber: string;
  transitNumber: string;
  accountNumber: string;
  verifyAccountNumber: string;
}

export interface NavigationButton {
  iconLink: string;
  buttonText: string;
  Icon: FC<IconProps>;
}

export interface Session {
  sessionMilliseconds: number;
  sessionID: string;
  isActive: boolean;
  startDate: string;
}

export interface SessionData {
  sessionid: string;
  email: string;
  firstname: string;
  lastname: string;
  organization_name: string | null;
  postal?: string;
  gift_type?: string;
  amount?: number;
  loyalty_card_number?: string;
  create_datetime?: string;
  language?: string;
  session_start_date: string;
  staged?: boolean;
  staged_date?: string;
  province?: string;
  transaction_complete: boolean;
}

export interface LookupIdResponseData {
  firstName: string;
  lastName: string;
  companyName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  postal: string | null;
  provinceState: string | null;
  country: string | null;
  phoneNumber: string | null;
  email: string;
  language: "en" | "fr";
  ask_type: string;
  isorganization: boolean;
  otg_ask_1?: number;
  otg_ask_2?: number;
  otg_ask_3?: number;
  mnt_ask_1?: number;
  mnt_ask_2?: number;
  mnt_ask_3?: number;
}

export interface ContextualImage {
  contextualImageDT: string;
  contextualImageM?: string;
}

export interface ThankYouBanner {
  en?: Record<string, Record<string, string>>;
  fr?: Record<string, Record<string, string>>;
}

export interface IconProps {
  color: string;
}

export type PointsCardVariant = "Aeroplan";

export type DonationType = "monthly" | "one-time";

export interface SelectOptionItem {
  value: string;
  name: string;
}

export interface InputAndSelectProps {
  isError?: string;
  isOverlayForm?: boolean;
}

export type TSchemaShape =
  | ReturnType<typeof schemaConstructor>
  | typeof overlayDonorInfoSchema
  | typeof overlayAddressInfoSchema;

export enum FormSourceType {
  GP_TEAM = "gp_team",
  FUNDRAISING_DIRECT = "fundraising_direct",
  DEVELOPMENT_TEAM = "development_team",
  DEVELOPMENT_TEAM_DONOR = "development_team_donor",
  OVERLAY_FORM = "overlay_form",
  MEMORIAL_GIVING_FORM = "memorial_giving",
}

export type HonourTypes = "in-honour" | "in-memory";
