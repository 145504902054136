import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DebitInfoValues,
  DonationInfoValues,
  DonationType,
  DonorInfoValues,
  Ecard,
  FormErrors,
  FormState,
} from "utils/interfaces";

const initialState: FormState = {
  donationInfo: {
    donationType: undefined,
    donationAmount: 0,
    honourType: "",
    honoureeName: "",
    honoureeFirstName: "",
    honoureeLastName: "",
    recipientFirstName: "",
    recipientLastName: "",
    honourCardType: "nocard",
    isCompanyGift: false,
    isTributeGift: false,
    formLanguage: "en",
    recipientEmail: "",
    emailSubject: "",
    personalMessage: "",
    isDuplicateEcard: false,
    cardActive: 0,
    coverTransactionFee: false,
    paymentMethod: "card",
    honoureeAddressLine: "",
    cardRecipientTitle: "",
    cardRecipientFullName: "",
    honoureeCity: "",
    honoureeCountry: "Canada",
    honoureePostal: "",
    honoureeProvinceState: "",
    source: "hsweb_nav",
    cardsCollection: {
      "in-honour": { en: [], fr: [] },
      "in-memory": { en: [], fr: [] },
    },
    loyaltyCardNumber: "",
    appealCode: undefined,
    isPrintReceipt: false,
    callCentreNumber: "",
    giftNotes: "",
    utcOffset: undefined,
  },
  donorInfo: {
    title: "",
    firstName: "",
    lastName: "",
    addressLine1: "",
    city: "",
    provinceState: "",
    postal: "",
    country: "Canada",
    addressLine2: "",
    phoneNumber: "",
    email: "",
    companyName: "",
  },
  debitAccountDetails: {
    institutionNumber: "",
    transitNumber: "",
    accountNumber: "",
    verifyAccountNumber: "",
  },
  formErrors: {},
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    updateDonationType(state, action: PayloadAction<DonationType | undefined>) {
      state.donationInfo.donationType = action.payload;
    },
    updateDonationAmount(state, action: PayloadAction<number>) {
      state.donationInfo.donationAmount = action.payload;
    },
    updateDonorInfo(state, action: PayloadAction<DonorInfoValues>) {
      state.donorInfo = { ...action.payload };
    },
    updateDonationInfo(state, action: PayloadAction<DonationInfoValues>) {
      state.donationInfo = { ...action.payload };
    },
    updateHonourType(state, action: PayloadAction<string>) {
      state.donationInfo.honourType = action.payload;
    },
    updateHonoureeName(state, action: PayloadAction<string>) {
      state.donationInfo.honoureeName = action.payload;
    },
    updateHonourCardType(state, action: PayloadAction<string>) {
      state.donationInfo.honourCardType = action.payload;
    },
    updateFormErrors(state, action: PayloadAction<FormErrors>) {
      state.formErrors = action.payload;
    },
    updateIsCompanyGift(state, action: PayloadAction<boolean>) {
      state.donationInfo.isCompanyGift = action.payload;
    },
    updateIsTributeGift(state, action: PayloadAction<boolean>) {
      state.donationInfo.isTributeGift = action.payload;
    },
    updateFormLanguage(state, action: PayloadAction<"en" | "fr">) {
      state.donationInfo.formLanguage = action.payload;
    },
    updateRecipientEmail(state, action: PayloadAction<string>) {
      state.donationInfo.recipientEmail = action.payload;
    },
    updateEmailSubject(state, action: PayloadAction<string>) {
      state.donationInfo.emailSubject = action.payload;
    },
    updatePersonalMessage(state, action: PayloadAction<string>) {
      state.donationInfo.personalMessage = action.payload;
    },
    updateIsDuplicateEcard(state, action: PayloadAction<boolean>) {
      state.donationInfo.isDuplicateEcard = action.payload;
    },
    updateCardActive(state, action: PayloadAction<number>) {
      state.donationInfo.cardActive = action.payload;
    },
    updateCoverTransactionFee(state, action: PayloadAction<boolean>) {
      state.donationInfo.coverTransactionFee = action.payload;
    },
    updateSource(state, action: PayloadAction<string>) {
      state.donationInfo.source = action.payload;
    },
    updatePaymentMethod(state, action: PayloadAction<"card" | "direct_debit">) {
      state.donationInfo.paymentMethod = action.payload;
    },
    updateHonoureeAddressLine(state, action: PayloadAction<string>) {
      state.donationInfo.honoureeAddressLine = action.payload;
    },
    updateHonoureeCity(state, action: PayloadAction<string>) {
      state.donationInfo.honoureeCity = action.payload;
    },
    updateHonoureeCountry(state, action: PayloadAction<string>) {
      state.donationInfo.honoureeCountry = action.payload;
    },
    updateHonoureeProvinceState(state, action: PayloadAction<string>) {
      state.donationInfo.honoureeProvinceState = action.payload;
    },
    updateHonoureePostal(state, action: PayloadAction<string>) {
      state.donationInfo.honoureePostal = action.payload;
    },
    updateCardsCollectionInMemory(
      state,
      action: PayloadAction<{
        en: Ecard[];
        fr: Ecard[];
      }>,
    ) {
      state.donationInfo.cardsCollection["in-memory"] = action.payload;
    },
    updateCardsCollectionInHonour(
      state,
      action: PayloadAction<{
        en: Ecard[];
        fr: Ecard[];
      }>,
    ) {
      state.donationInfo.cardsCollection["in-honour"] = action.payload;
    },
    updateLoyaltyCardNumber(state, action: PayloadAction<string>) {
      state.donationInfo.loyaltyCardNumber = action.payload;
    },
    updateCardRecipientTitle(state, action: PayloadAction<string>) {
      state.donationInfo.cardRecipientTitle = action.payload;
    },
    updateCardRecipientFullName(state, action: PayloadAction<string>) {
      state.donationInfo.cardRecipientFullName = action.payload;
    },
    updateAppealCode(state, action: PayloadAction<string>) {
      state.donationInfo.appealCode = action.payload;
    },
    updateIsPrintReceipt(state, action: PayloadAction<boolean>) {
      state.donationInfo.isPrintReceipt = action.payload;
    },
    updateCallCentreNumber(state, action: PayloadAction<string>) {
      state.donationInfo.callCentreNumber = action.payload;
    },
    updateGiftNotes(state, action: PayloadAction<string>) {
      state.donationInfo.giftNotes = action.payload;
    },
    updateDebitAccountDetails(state, action: PayloadAction<DebitInfoValues>) {
      state.debitAccountDetails = action.payload;
    },
    resetFormReducer: () => initialState,
  },
});

export const {
  updateDonationType,
  updateDonationAmount,
  updateDonorInfo,
  updateHonourType,
  updateHonoureeName,
  updateHonourCardType,
  updateFormErrors,
  updateIsTributeGift,
  updateIsCompanyGift,
  updateFormLanguage,
  updateRecipientEmail,
  updateEmailSubject,
  updatePersonalMessage,
  updateIsDuplicateEcard,
  updateCardActive,
  updateCoverTransactionFee,
  updateSource,
  updatePaymentMethod,
  updateHonoureeAddressLine,
  updateHonoureeCity,
  updateHonoureeCountry,
  updateHonoureePostal,
  updateHonoureeProvinceState,
  updateDonationInfo,
  updateCardsCollectionInMemory,
  updateCardsCollectionInHonour,
  updateLoyaltyCardNumber,
  updateCardRecipientFullName,
  updateCardRecipientTitle,
  updateAppealCode,
  updateIsPrintReceipt,
  updateCallCentreNumber,
  updateDebitAccountDetails,
  updateGiftNotes,
  resetFormReducer,
} = formSlice.actions;

export default formSlice.reducer;
