import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { TFunction } from "i18next";
import { FormErrors } from "./interfaces";
import datalayerAPI from "./datalayerAPI";
import { overlayStepNumberToNameMap } from "./variables";

class AnalyticsAPI {
  // NAVIGATION ANALYTICS
  handleNavigationButton(
    appInsights: ReactPlugin,
    parameters: { buttonID: number; buttonText: string },
  ) {
    appInsights.trackEvent({
      name: "Tab navigate",
      properties: { index: parameters.buttonID, button: parameters.buttonText },
    });
  }

  handleTYBannerClick(
    appInsights: ReactPlugin,
    properties: { date: string; bannerLink: string; bannerImageLink: string },
  ) {
    appInsights.trackEvent({
      name: "TY banner click",
      properties: properties,
    });
  }

  handleBackButton(
    appInsights: ReactPlugin,
    parameters: { currentForm: number },
  ) {
    appInsights.trackEvent({
      name: "Back button",
      properties: { currentForm: parameters.currentForm },
    });
  }

  handleNextStep(
    appInsights: ReactPlugin,
    parameters: { currentForm: number },
  ) {
    appInsights.trackEvent({
      name: "Next step",
      properties: { currentForm: parameters.currentForm },
    });
  }

  // DONATION TYPE ANALYTICS
  handleOtherSelected(
    appInsights: ReactPlugin,
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    appInsights.trackEvent({
      name: "Other amount select",
      properties: { amount: e.currentTarget.value },
    });
  }

  handleAddressCompleteError(
    appInsights: ReactPlugin,
    errorMessage: string,
    placeId: string,
  ) {
    appInsights.trackEvent({
      name: "Other amount select",
      properties: { placeId, errorMessage },
    });
  }

  handleAskSelect(
    appInsights: ReactPlugin,
    e: React.MouseEvent<HTMLButtonElement>,
  ) {
    appInsights.trackEvent({
      name: "Ask select",
      properties: {
        index: e.currentTarget.dataset.id,
        amount: e.currentTarget.value,
      },
    });
  }

  // PAYMENT ANALYTICS
  handleSuccessfulPayment(
    appInsights: ReactPlugin,
    parameters: {
      name: string;
      donationAmount: number;
      donationType: "monthly" | "one-time" | undefined;
      ecardId: number;
      paymentId: string;
      isCoverFees: boolean;
      honourType: string;
      honourCardType: string;
      isCompanyGift: boolean;
      isOverlayForm: boolean;
    },
  ) {
    appInsights.trackEvent({
      name: parameters.name,
      properties: {
        amount: parameters.donationAmount,
        donationType: parameters.donationType,
        ecardId: parameters.ecardId,
      },
    });

    datalayerAPI.handleSuccessfulPayment(parameters);
  }

  // TRACK PAGE VIEW
  handleTrackPageView(
    appInsights: ReactPlugin,
    parameters: { name: string; pageEngagementStartTime: number },
  ) {
    appInsights.trackPageView({
      name: parameters.name,
      properties: { duration: Date.now() - parameters.pageEngagementStartTime },
    });
  }

  handleErrors({
    formErrors,
    appInsights,
    t,
    isOverlayForm,
    currentForm,
  }: {
    formErrors: FormErrors;
    appInsights: ReactPlugin;
    t: TFunction;
    isOverlayForm: boolean;
    currentForm: number;
  }) {
    const formErrorsArray = Object.values(formErrors);
    const formErrorsFullNames = formErrorsArray.map((error) => t(error));

    isOverlayForm
      ? datalayerAPI.handleOverlayValidationErrors(
          currentForm + 1,
          overlayStepNumberToNameMap[currentForm],
        )
      : datalayerAPI.handleValidationErrors(formErrorsFullNames);
    appInsights.trackEvent({
      name: "Form error",
      properties: { errors: formErrors },
    });
  }

  handleRecaptcha(appInsights: ReactPlugin, token: string, score: number) {
    appInsights.trackEvent({
      name: "Recaptcha score",
      properties: {
        token,
        score,
      },
    });
  }
}

export default new AnalyticsAPI();
