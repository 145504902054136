import styled, { CSSProperties } from "styled-components";
import { black } from "utils/variables";

export const HeadingContainer = styled.div(
  ({ isWithButton }: { isWithButton: boolean }) => ({
    position: "relative" as CSSProperties["position"],
    marginBottom: 24,
    textAlign: (isWithButton ? "center" : "left") as CSSProperties["textAlign"],

    button: {
      position: "absolute" as CSSProperties["position"],
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
      background: "none",
      border: "none",
      cursor: "pointer",
      display: "flex",
    },
  }),
);

export const StyledHeading = styled.h2`
  font-size: 22px;
  font-weight: bold;
  color: ${black};
`;
