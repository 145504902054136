import styled, { css, keyframes } from "styled-components";

const loading = keyframes`
  0% {
    background: #f0f0f0;
  }

  50% {
    background: #dbdbdb;
  }

  100% {
    background: #f0f0f0;
  }
`;

export const SkeletonItem = styled.div<{
  width: string;
  height: string;
  backgroundColor: string;
  withAnimation?: boolean;
}>`
  display: flex;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;
  transition: all 0.4s;
  ${(props) =>
    props.withAnimation
      ? css`
          animation: ${loading} 2s infinite;
        `
      : ""}
`;
