import styled from "styled-components";
import { black, overlayFormBorderRadius } from "utils/variables";

export const OverlayPaymentSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 20px;
  border-top: 1px solid ${black};

  p:last-of-type {
    margin-top: 2px;
  }
`;

export const TotalAmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 17px;

    &.bold {
      font-weight: bold;
    }
  }
`;

export const ContextualBlock = styled.div`
  background-color: #ededed;
  border-radius: ${overlayFormBorderRadius};
  padding: 6px 8px;
  margin-bottom: 10px;
`;
