import styled, { createGlobalStyle, CSSProperties } from "styled-components";
import {
  lightGrey,
  outlineColor,
  primaryColor,
  standardBorderRadius,
} from "utils/variables";
import NeueRegular from "./fonts/NHaasGroteskDSPro-55Rg.ttf";
import NeueMedium from "./fonts/NHaasGroteskDSPro-65Md.ttf";

interface Props {
  formID?: number;
  currentForm?: number;
  isOverlayForm?: boolean;
}

interface PageParams {
  viewportHeight: number;
  isOverlayForm?: boolean;
}

export default createGlobalStyle`
    @font-face {
      font-family: 'Neue';
      src: url(${NeueRegular}) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Neue';
      src: url(${NeueMedium}) format('truetype');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        ${(props: { isOverlayForm?: boolean }) => `font-family: '${props.isOverlayForm ? "Neue" : "Arial"}', sans-serif;`}
    }
    #root{
        margin: 0 auto;
        height: 100%;
        white-space: pre-line;
    }
    html,
    body {
        height: auto;
        margin: 0;
        @media only screen and (min-width: 1081px) {
          overflow: hidden;
        }    
    }

    h2{
        font-weight: 700;
        font-size: 32px;
    }

    .grecaptcha-badge{
      ${(props: { isOverlayForm?: boolean }) => (props.isOverlayForm ? "display: none;" : "")}
    }
`;

export const PageContainer = styled.div`
  display: flex;
  height: ${(props: PageParams) =>
    props.isOverlayForm ? "auto" : `${props.viewportHeight - 72.5}px`};
  position: relative;

  @media only screen and (max-width: 1080px) {
    flex-direction: column-reverse;
    height: auto;
  }
`;

export const SectionHeading = styled.h2`
  margin-bottom: 12px;
  font-size: 27px;

  @media only screen and (max-width: 550px) {
    font-size: 21px;
  }
`;

export const FormPartContainer = styled.section(
  ({ isOverlayForm, currentForm = 0, formID = 0 }: Props) => ({
    transition: "transform 1s",
    padding: isOverlayForm ? "24px" : "36px 30px",
    transform: `translateX(${(formID - currentForm) * 100}%)`,

    ...(currentForm === formID
      ? {
          opacity: 1,
          position: "static" as CSSProperties["position"],
          visibility: "visible" as CSSProperties["visibility"],
        }
      : {
          opacity: 0,
          position: "absolute" as CSSProperties["position"],
          visibility: "hidden" as CSSProperties["visibility"],
        }),

    "@media only screen and (max-width: 550px)": {
      padding: isOverlayForm ? "24px" : "18px 8px",
    },
  }),
);

export const RequiredText = styled.span`
  color: ${primaryColor};
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 23px;
  display: inline-block;

  @media only screen and (max-width: 550px) {
    font-size: 16px;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  /* Support for gap in modern browsers */
  @supports (gap: 10px) {
    gap: 0 38px;
  }

  @media only screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const TwoColumnsInput = styled.div`
  display: flex;
  gap: 8px;
  > div {
    width: calc(50% - 4px);
  }
`;

export const InputContainer = styled.div(
  ({ isOverlayForm }: { isOverlayForm?: boolean }) => ({
    display: "flex",
    flexDirection: "column" as CSSProperties["flexDirection"],
    flexGrow: 1,
    marginBottom: isOverlayForm ? "20px" : "25px",

    "& label": {
      marginBottom: isOverlayForm ? "8px" : "6px",
      fontSize: isOverlayForm ? "15px" : "22px",

      "@media only screen and (max-width: 550px)": {
        fontSize: isOverlayForm ? "15px" : "20px",
      },
    },

    "@media only screen and (max-width: 550px)": {
      width: "100%",
    },
  }),
);

export const RedStar = styled.span`
  color: ${primaryColor};
`;

export const TextArea = styled.textarea<{ isError?: string }>`
  width: 100%;
  padding: 7px;
  font-size: 20px;
  min-height: 150px;
  ${(props) => props.isError && `border: 1px solid ${primaryColor}`};

  &:focus {
    outline: solid ${outlineColor} 2px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  /* margin-bottom: 30px; */

  & input:focus-visible {
    outline: solid #333;
  }

  & label {
    margin-left: 8px;
    font-size: 18px;

    @media only screen and (max-width: 550px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 320px) {
      font-size: 12px;
    }
  }
`;

export const NextStepButton = styled.button(
  ({ isOverlayForm }: { isOverlayForm?: boolean }) => ({
    fontSize: isOverlayForm ? "15px" : "19px",
    color: "#fff",
    backgroundColor: primaryColor,
    border: "none",
    padding: isOverlayForm ? "10px 62px" : "19px 37px",
    cursor: "pointer",
    borderRadius: isOverlayForm ? "24px" : standardBorderRadius,
    marginLeft: "auto",
    textAlign: "right" as CSSProperties["textAlign"],
    fontWeight: isOverlayForm ? "bold" : 400,

    "&:focus-visible": {
      outline: "solid #333 3px",
    },

    "&:disabled": {
      cursor: "default",
      background: lightGrey,
    },

    ...(!isOverlayForm && {
      "@media only screen and (max-width: 550px)": {
        fontSize: "17px",
      },

      "@media only screen and (max-width: 320px)": {
        fontSize: "15px",
        padding: "17px 31px",
      },
    }),
  }),
);

export const BottomButtonsContainer = styled.div(
  ({ isOverlayForm }: { isOverlayForm?: boolean }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: isOverlayForm ? "48px" : "30px",
  }),
);

export const TextAreaCharLimit = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 8px;

  @supports (gap: 10px) {
    gap: 3px;
  }
`;

export const RegularParagraph = styled.p`
  font-size: 20px;

  a {
    color: ${primaryColor};
    font-weight: 700;
  }
`;
