import styled, { CSSProperties } from "styled-components";
import { primaryColor } from "utils/variables";

export const ErrorMessageStyled = styled.p(
  ({ isOverlayForm }: { isOverlayForm: boolean }) => ({
    color: primaryColor,
    fontSize: isOverlayForm ? "13px" : "20px",
    marginTop: isOverlayForm ? "4px" : "18px",

    span: {
      border: `1px solid ${primaryColor}`,
      borderRadius: "50%",
      width: isOverlayForm ? "17px" : "23px",
      height: isOverlayForm ? "17px" : "23px",
      textAlign: "center" as CSSProperties["textAlign"],
      display: "inline-block",
    },

    "@media only screen and (max-width: 550px)": {
      fontSize: isOverlayForm ? "13px" : "18px",
    },
  }),
);
