import React, { useEffect, useState } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { updateFormLanguage } from "redux/form/form.reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import {
  RightBlockContainer,
  LeftBlockContainer,
  CampaignMemoContainer,
} from "pages/donation/donation-page.styles";

import { DoctorNameBox } from "./thank-you.styles";
import analyticsAPI from "utils/analyticsAPI";
import Footer from "components/footer/footer.component";
import {
  selectResearcherName,
  selectThankYouContextualBoxHTML,
} from "redux/helpers/helpers.selector";
import { PageContainer } from "global";
import ContextualImage from "components/contextual-image/contextual-image.component";
import ThankYouContent from "components/thank-you-content/thank-you-content.component";
import { FormSourceType } from "utils/interfaces";
import { selectFormLanguage } from "redux/form/form.selector";

interface Props {
  viewportHeight: number;
}

const ThankYouPage: React.FC<Props> = ({ viewportHeight }) => {
  // URL parameteres
  const [searchParams] = useSearchParams();
  const formTypeURL = searchParams.get("type");
  const presValue = searchParams.get("pres");
  const source = searchParams.get("source");
  const lookupId = searchParams.get("lookupId");
  const locale = searchParams.get("locale");
  const cid = searchParams.get("cid");

  // LOCAL STATE
  const [newDonationUrl, setNewDonationUrl] = useState("");
  const isOverlayForm = source === FormSourceType.OVERLAY_FORM;
  const isMemorialGivingForm = source === FormSourceType.MEMORIAL_GIVING_FORM;

  // GLOBAL STATE
  const researcherName = useAppSelector(selectResearcherName);
  const thankYouContextualBoxHTML = useAppSelector(
    selectThankYouContextualBoxHTML,
  );
  const formLanguage = useAppSelector(selectFormLanguage);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Analytics
  const appInsights = useAppInsightsContext();
  const pageEngagementStartTime = Date.now();

  useEffect(() => {
    analyticsAPI.handleTrackPageView(appInsights, {
      name: "ThankYouPage",
      pageEngagementStartTime,
    });

    // Create URL with the same parameters as the donation
    setNewDonationUrl(
      `${window.location.origin}?${
        formTypeURL ? "type=" + formTypeURL + "&" : ""
      }${presValue ? "pres=" + presValue + "&" : ""}${
        source ? "source=" + source + "&" : ""
      }${lookupId ? "lookupId=" + lookupId + "&" : ""}${cid ? "cid=" + cid + "&" : ""}${
        locale ? "locale=" + locale : ""
      }`,
    );
    if (locale?.toLowerCase() === "fr") {
      dispatch(updateFormLanguage("fr"));
    }
    return () => {
      const engagementTimeSeconds =
        (Date.now() - pageEngagementStartTime) / 1000;
      const metricData = {
        average: Math.round(engagementTimeSeconds * 100) / 100,
        name: "React Component Engaged Time (seconds)",
        sampleCount: 1,
      };
      const additionalProperties = { "Component Name": "ThankYouPage" };
      appInsights.trackMetric(metricData, additionalProperties);
    };
  }, []); // eslint-disable-line

  return (
    <PageContainer viewportHeight={viewportHeight}>
      <LeftBlockContainer
        isOverlayForm={isOverlayForm}
        id="left-section"
        isContextual={true}
      >
        <main>
          <ThankYouContent
            newDonationUrl={newDonationUrl}
            variant={isOverlayForm ? "overlay" : "regular"}
          />
        </main>

        {!isOverlayForm && <Footer />}
      </LeftBlockContainer>
      {!isOverlayForm && (
        <RightBlockContainer isContextual={true}>
          <ContextualImage isTYPage />
          {thankYouContextualBoxHTML[formLanguage] ? (
            <CampaignMemoContainer
              data-cy="redBox"
              dangerouslySetInnerHTML={{
                __html: thankYouContextualBoxHTML[formLanguage],
              }}
            />
          ) : (
            !isMemorialGivingForm && (
              <DoctorNameBox>
                {t("TY researcher name", { researcherName })}
              </DoctorNameBox>
            )
          )}
        </RightBlockContainer>
      )}
    </PageContainer>
  );
};

export default ThankYouPage;
