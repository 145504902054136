import styled from "styled-components";
import {
  outlineColor,
  primaryColor,
  standardBorderRadius,
  standardBorderWidth,
} from "../../utils/variables";

export const HorizontalLine = styled.hr`
  margin-bottom: 20px;
`;

export const CCReportTributeLink = styled.a`
  color: ${primaryColor};
  transition:
    background-color 0.4s,
    color 0.4s;
  cursor: pointer;
  border: ${standardBorderWidth} solid ${primaryColor};
  background-color: #fff;
  border-radius: ${standardBorderRadius};
  padding: 17px 25px;
  font-size: 19px;
  font-weight: 700;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 20px;
  width: 46.5%;
  text-align: center;

  &:focus-visible {
    outline: solid ${outlineColor} 3px;
  }

  &:hover {
    background-color: ${primaryColor};
    color: #fff;
  }
`;
